import * as Yup from 'yup';

const conceptName = Yup.string()
  .max(128, 'Requiere máximo 128 caracteres.')
  .trim()
  .required('Requerido.');

const conceptGroup = Yup.object().required('Requerido.').nullable();

const isActive = Yup.bool().required();

export const CONCEPT_ADD_SCHEMA = Yup.object().shape({
  conceptName,
  conceptGroup,
});

export const CONCEPT_EDIT_SCHEMA = Yup.object().shape({
  conceptName,
  conceptGroup,
  isActive,
});
