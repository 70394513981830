import getError from '../getError';
import instance from '../instance';

export const getDashboard = async query => {
  try {
    const res = await instance.get('/dashboard', { params: query });
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error consultando datos.');
    throw newError;
  }
};
