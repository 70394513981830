import * as Yup from 'yup';

const conceptGroupName = Yup.string()
  .max(128, 'Requiere máximo 128 caracteres.')
  .trim()
  .required('Requerido.');

const account = Yup.string()
  .max(128, 'Requiere máximo 128 caracteres.')
  .trim()
  .nullable();

const department = Yup.object().required('Requerido.').nullable();

const isActive = Yup.bool().required();

export const CONCEPTGROUP_ADD_SCHEMA = Yup.object().shape({
  conceptGroupName,
  account,
  department,
});

export const CONCEPTGROUP_EDIT_SCHEMA = Yup.object().shape({
  conceptGroupName,
  account,
  department,
  isActive,
});
