import getError from '../getError';
import instance from '../instance';

export const getExpenseExport = async dates => {
  try {
    const res = await instance.get('/exports/expenses', { params: { dates } });
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error consultando datos para exportar.');
    throw newError;
  }
};

export const getDistributionSummaryExport = async dates => {
  try {
    const res = await instance.get('/exports/distributions/summary', {
      params: { dates },
    });
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error consultando datos para exportar.');
    throw newError;
  }
};

export const getDistributionDetailExport = async dates => {
  try {
    const res = await instance.get('/exports/distributions/detail', {
      params: { dates },
    });
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error consultando datos para exportar.');
    throw newError;
  }
};
