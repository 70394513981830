import { useState } from 'react';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';

import FormAutocomplete from '../../../../Components/forms/FormAutocomplete';
import FormDatePicker from '../../../../Components/forms/FormDatePicker';
import FormTextbox from '../../../../Components/forms/FormTextbox';
import LoadingButton from '../../../../Components/LoadingButton';

import { ADD_NDE_SCHEMA } from '../../../../constants/forms/schemas';
import { ADD_NDE_INITIAL_VALUES } from '../../../../constants/forms/initialValues';
import {
  getSuppliers,
  getConceptGroups,
  getConcepts,
  createNonDeductibleExpense,
} from '../../../../api/services';
import useAuth from '../../../../context/useAuth';

const AddNonDeductible = () => {
  const { user, setUser } = useAuth();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(!true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [query, setQuery] = useState({
    supplier: { isActive: true },
    conceptGroup: { isActive: true, department: user.department?._id },
  });

  const handleConceptGroupChange = async newConceptGroup => {
    if (newConceptGroup)
      setQuery(prevState => {
        return {
          ...prevState,
          concept: { isActive: true, conceptGroup: newConceptGroup._id },
        };
      });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <Formik
        initialValues={ADD_NDE_INITIAL_VALUES}
        onSubmit={async (values, { resetForm }) => {
          try {
            if (!user.department) {
              toast.error('Usuario no cuenta con departamento');
              return;
            }

            setLoading(true);

            const { conceptGroup, concept, supplier, amount } = values;

            await createNonDeductibleExpense({
              ...values,
              amount: Number(amount).toFixed(2),
              department: user.department?._id,
              uuid: null,
              isDeductible: false,
              invoice: null,
              conceptGroup: conceptGroup._id,
              concept: concept._id,
              supplier: supplier._id,
            });

            resetForm();
            toast.success(`Gasto agregado con éxito.`);
          } catch (error) {
            if (error.status === 409) {
              setError(error.message);
              return;
            }

            if (error.status === 401) setUser(null);
            toast.error(error.message);
          } finally {
            setLoading(false);
          }
        }}
        validationSchema={ADD_NDE_SCHEMA}
      >
        {props => (
          <Container maxWidth="sm">
            <Form>
              <Grid
                container
                spacing={2}
                sx={{
                  mb: 4,
                }}
              >
                {error && (
                  <Grid item xs={12}>
                    <Alert
                      onClose={() => setError(null)}
                      severity="error"
                      sx={{
                        mb: 2,
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: 'error.dark',
                      }}
                    >
                      {error}
                    </Alert>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <FormDatePicker
                    loading={loading}
                    name="date"
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormTextbox
                    currency
                    disabled={loading}
                    label="Subtotal"
                    name="amount"
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormAutocomplete
                    disabled={loading}
                    label="Proveedor"
                    name="supplier"
                    property="supplierName"
                    query={query.supplier}
                    source={getSuppliers}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormAutocomplete
                    disabled={loading}
                    label="Grupo"
                    name="conceptGroup"
                    property="conceptGroupName"
                    query={query.conceptGroup}
                    source={getConceptGroups}
                    onChange={handleConceptGroupChange}
                  />
                </Grid>

                {query.concept && (
                  <Grid item xs={12}>
                    <FormAutocomplete
                      disabled={loading}
                      label="Concepto"
                      name="concept"
                      property="conceptName"
                      query={query.concept}
                      source={getConcepts}
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <FormTextbox
                    disabled={loading}
                    label="Descripción"
                    multiline
                    name="description"
                  />
                </Grid>
              </Grid>

              {/* Buttons */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <LoadingButton
                  loading={loading}
                  icon={<AddIcon />}
                  text={'Guardar'}
                />
              </Box>
            </Form>
          </Container>
        )}
      </Formik>
    </Box>
  );
};

export default AddNonDeductible;
