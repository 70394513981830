import getError from '../getError';
import instance from '../instance';

export const me = async () => {
  try {
    const res = await instance.post('/auth/me');
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error consultando credenciales.');
    throw newError;
  }
};

export const login = async data => {
  try {
    const res = await instance.post('/auth/login', data);
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error iniciando sesión.', {
      401: 'Credenciales inválidas.',
    });
    throw newError;
  }
};

export const logout = async () => {
  try {
    await instance.post('/auth/logout');
  } catch (error) {
    const newError = getError(error, 'Error cerrando sesión.');
    throw newError;
  }
};
