import getError from '../getError';
import instance from '../instance';

export const getFamilies = async query => {
  try {
    const res = await instance.get('/families', { params: query });
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error consultando familias.');
    throw newError;
  }
};

export const createFamily = async data => {
  try {
    const res = await instance.post('/families', data);
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error creando familia.');
    throw newError;
  }
};

export const editFamily = async data => {
  try {
    const res = await instance.put('/families', data);
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error editando familia.');
    throw newError;
  }
};
