import linkList from '../constants/links';

const getLinks = userRole => {
  const validLinks = [];

  linkList.forEach(linkObj => {
    if (linkObj.roles.includes(userRole) || userRole === 'SUPERADMIN') {
      validLinks.push(linkObj.link);
    }
  });

  return validLinks;
};

export default getLinks;
