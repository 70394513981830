/**
 * It takes a excel date serial number, and returns a Date
 * @param excelDate The date in Excel format.
 * @Output a Date object.
 */

const excelDateToDate = excelDate => {
  return new Date(
    Math.round((excelDate - 25569) * 86400 * 1000)
  ).toDateString();
};

export default excelDateToDate;
