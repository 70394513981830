import getError from '../getError';
import instance from '../instance';

export const getConcepts = async query => {
  try {
    const res = await instance.get('/concepts', { params: query });
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error consultando conceptos.');
    throw newError;
  }
};

export const createConcept = async data => {
  try {
    const res = await instance.post('/concepts', data);
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error creando conceptos.');
    throw newError;
  }
};

export const editConcept = async data => {
  try {
    const res = await instance.put('/concepts', data);
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error editando conceptos.');
    throw newError;
  }
};
