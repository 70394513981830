const ADD_NDE_INITIAL_VALUES = {
  date: new Date(new Date().toDateString()),
  amount: '',
  department: null,
  supplier: null,
  conceptGroup: null,
  concept: null,
  description: '',
};

export default ADD_NDE_INITIAL_VALUES;
