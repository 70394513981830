import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import useAuth from '../../context/useAuth';

const Home = () => {
  const { user } = useAuth();

  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          display: 'flex',
          height: '100%',
          p: 2,
        }}
      >
        <Box
          component="img"
          src="images/home.svg"
          sx={{
            position: 'absolute',
            opacity: 0.06,
            height: '60vh',
          }}
        />

        <Typography
          color="primary.main"
          sx={{
            mb: 5,
            fontSize: { xs: 'h4.fontSize', md: 'h3.fontSize' },
            textAlign: 'center',
          }}
          variant="h2"
        >
          DISTRIBUCIÓN DE GASTOS
        </Typography>
        <Typography
          sx={{
            opacity: 0.8,
            fontSize: { xs: 'h6.fontSize', md: 'h5.fontSize' },
          }}
          color="primary.main"
          variant="h5"
        >
          BIENVENIDO, {user.firstName}.
        </Typography>
      </Box>
    </>
  );
};

export default Home;
