import EditIcon from '@mui/icons-material/Edit';
import { GridActionsCellItem } from '@mui/x-data-grid';

const CONCEPTGROUP_COLUMNS = handleEdit => [
  {
    field: 'conceptGroupName',
    headerName: 'Grupo',
    flex: 3,
    minWidth: 300,
  },
  {
    field: 'account',
    headerName: 'Cuenta',
    flex: 3,
    minWidth: 300,
    valueGetter: params => params.row.account || 'N/A',
  },
  {
    field: 'department',
    headerName: 'Departamento',
    flex: 2,
    minWidth: 200,
    align: 'center',
    headerAlign: 'center',
    valueGetter: params => params.row.department.departmentName,
  },
  {
    field: 'isActive',
    headerName: 'Activo',
    type: 'boolean',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: 'Acciones',
    flex: 1,
    minWidth: 100,
    align: 'center',
    getActions: params => [
      <GridActionsCellItem
        icon={<EditIcon />}
        label="Editar"
        onClick={() => handleEdit(params)}
      />,
    ],
  },
];

export default CONCEPTGROUP_COLUMNS;
