import EditIcon from '@mui/icons-material/Edit';
import { GridActionsCellItem } from '@mui/x-data-grid';

const PARTICIPATION_COLUMNS = handleEdit => [
  { field: 'year', headerName: 'Año', flex: 1, minWidth: 100 },
  {
    field: 'chainName',
    headerName: 'Cadena',
    flex: 6,
    minWidth: 200,
    valueGetter: params => params.row.chain.chainName,
  },
  {
    field: 'percentage',
    headerName: 'Participación',
    flex: 1,
    type: 'number',
    minWidth: 100,
    valueGetter: params => `${Number(params.row.percentage).toFixed(2)}%`,
  },
  {
    field: 'isActive',
    headerName: 'Activo',
    type: 'boolean',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: 'Acciones',
    flex: 1,
    minWidth: 100,
    align: 'center',
    getActions: params => [
      <GridActionsCellItem
        icon={<EditIcon />}
        label="Editar"
        onClick={() => handleEdit(params)}
      />,
    ],
  },
];

export default PARTICIPATION_COLUMNS;
