import { useState, useEffect, useCallback } from 'react';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import ExpenseDetail from '../ExpenseDetail';
import Summary from '../../Components/Summary';
import { ContentContainer, ContentBox, TableBox } from '../../../../theme';
import Autocomplete from '../../../../Components/Autocomplete';
import FormAutocomplete from '../../../../Components/forms/FormAutocomplete';
import FormTextbox from '../../../../Components/forms/FormTextbox';
import LoadingButton from '../../../../Components/LoadingButton';
import Modal from '../../../../Components/Modal';
import Table from '../../../../Components/Table';

import { DISTRIBUTION_COLUMNS } from '../../../../constants/tables/columns';
import { DISTRIBUTION_INITIAL_VALUES } from '../../../../constants/forms/initialValues';
import { DISTRIBUTION_FIELDS } from '../../../../constants/tables/fields';
import { useModal } from '../../../../hooks';
import {
  getConcepts,
  getConceptGroups,
  editExpenseConceptGroup,
  editExpenseConcept,
  getDistributions,
  createDistribution,
  deleteDistribution,
} from '../../../../api/services';
import { formatDate, reduceFromObjects } from '../../../../utils';
import { DISTRIBUTION_ADD_SCHEMA } from '../../../../constants/forms/schemas';
import useAuth from '../../../../context/useAuth';

const Distributions = ({ exit, selectedExpense }) => {
  const [deleteData, setDeleteData] = useState(null);
  const [distributions, setDistributions] = useState(null);
  const [error, setError] = useState(null);
  const [expense, setExpense] = useState(selectedExpense);
  const [loading, setLoading] = useState(true);

  const { setUser } = useAuth();

  const [conceptGroupQuery] = useState({
    isActive: true,
    department: selectedExpense.department?._id,
  });

  const [conceptQuery, setConceptQuery] = useState(
    selectedExpense.conceptGroup
      ? {
          isActive: true,
          conceptGroup: selectedExpense.conceptGroup?._id,
        }
      : null
  );

  const [formQueries, setFormQueries] = useState({
    zone: { isActive: true },
    plaza: { isActive: true },
    agent: { isActive: true },
    chain: { isActive: true },
    family: { isActive: true },
  });

  const [open, setOpen] = useModal({ ref: false });
  const [openConfirmation, setOpenConfirmation] = useModal({ ref: false });

  const amountDistributed = +reduceFromObjects(distributions, 'amount').toFixed(
    2
  );
  const amountLeft = +(expense.amount - amountDistributed).toFixed(2);

  const fetchData = useCallback(async () => {
    try {
      const res = await getDistributions({ expense: expense._id });
      setDistributions(res);
      setLoading(false);
    } catch (error) {
      if (error.status === 401) setUser(null);
      toast.error(error.message);
    }
  }, [expense._id, setUser]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Handlers
  const handleConceptGroupChange = async newConceptGroup => {
    const oldConceptGroup = expense.conceptGroup
      ? Object.assign({}, expense.conceptGroup)
      : null;

    try {
      setExpense(prevState => {
        return { ...prevState, conceptGroup: newConceptGroup, concept: null };
      });

      await editExpenseConceptGroup(expense._id, newConceptGroup?._id || null);

      if (newConceptGroup) {
        setConceptQuery({
          isActive: true,
          conceptGroup: newConceptGroup._id,
        });
      }

      toast.success('Grupo editado con éxito.');
    } catch (error) {
      if (error.status === 401) setUser(null);
      else
        setExpense(prevState => {
          return { ...prevState, conceptGroup: oldConceptGroup };
        });

      toast.error(error.message);
    }
  };

  const handleConceptChange = async newConcept => {
    const oldConcept = expense.concept
      ? Object.assign({}, expense.concept)
      : null;

    try {
      setExpense(prevState => {
        return { ...prevState, concept: newConcept };
      });
      await editExpenseConcept(expense._id, newConcept?._id || null);

      toast.success('Concepto editado con éxito.');
    } catch (error) {
      if (error.status === 401) setUser(null);
      else
        setExpense(prevState => {
          return { ...prevState, concept: oldConcept };
        });

      toast.error(error.message);
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);

      await deleteDistribution({ _id: deleteData._id });

      toast.success('Distribución borrada con éxito.');

      await fetchData();
      setOpenConfirmation(false);
    } catch (error) {
      if (error.status === 401) setUser(null);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteRequest = data => {
    setDeleteData(data.row);
    setOpenConfirmation(true);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenConfirmation(false);
    setError(null);
  };

  const handleQueryChange = (name, newValue) => {
    if (name === 'zone' && newValue) {
      setFormQueries(prevState => {
        return {
          ...prevState,
          plaza: { isActive: true, zone: newValue._id },
          family: { isActive: true, zone: newValue._id },
        };
      });
    }
    if (name === 'zone' && !newValue) {
      setFormQueries(prevState => {
        return {
          ...prevState,
          plaza: { isActive: true },
          family: { isActive: true },
        };
      });
    }

    if (name === 'plaza' && newValue) {
      setFormQueries(prevState => {
        return {
          ...prevState,
          agent: { isActive: true, plazas: newValue._id },
          family: { isActive: true, plazas: newValue._id },
        };
      });
    }
    if (name === 'plaza' && !newValue) {
      setFormQueries(prevState => {
        return {
          ...prevState,
          agent: { isActive: true },
          family: { isActive: true },
        };
      });
    }

    if (name === 'agent' && newValue) {
      setFormQueries(prevState => {
        return {
          ...prevState,
          chain: { isActive: true, agents: newValue._id },
          family: { isActive: true, agents: newValue._id },
        };
      });
    }
    if (name === 'agent' && !newValue) {
      setFormQueries(prevState => {
        return {
          ...prevState,
          chain: { isActive: true },
          family: { isActive: true },
        };
      });
    }

    if (name === 'chain' && newValue) {
      setFormQueries(prevState => {
        return {
          ...prevState,
          family: { isActive: true, chains: newValue._id },
        };
      });
    }
    if (name === 'chain' && !newValue) {
      setFormQueries(prevState => {
        return {
          ...prevState,
          family: { isActive: true },
        };
      });
    }
  };

  return (
    <>
      <ContentContainer maxWidth="xl">
        <ContentBox>
          <TableBox>
            {/* Header */}
            <Box>
              <Box
                sx={{ justifyContent: 'space-between', display: 'flex', mb: 1 }}
              >
                <Box width="60%">
                  <ExpenseDetail
                    title="Proveedor"
                    content={expense.supplier.supplierName}
                    tooltip
                  />
                </Box>
                <Box>
                  <ExpenseDetail
                    title="Fecha"
                    content={formatDate(expense.date)}
                  />
                </Box>
              </Box>

              <Box
                sx={{ justifyContent: 'space-between', display: 'flex', mb: 1 }}
              >
                <ExpenseDetail
                  content={expense.supplier.supplierRfc || 'N/A'}
                  title="RFC"
                />
                <ExpenseDetail
                  content={expense.isDeductible ? 'Deducible' : 'No deducible'}
                  title="Tipo"
                />
              </Box>

              <Box
                sx={{ justifyContent: 'space-between', display: 'flex', mb: 1 }}
              >
                <Box width="60%">
                  <ExpenseDetail
                    content={expense.description}
                    title="Descripción"
                    tooltip
                  />
                </Box>
                <Box>
                  <ExpenseDetail
                    title="Departamento"
                    content={expense.department.departmentName}
                  />
                </Box>
              </Box>
              <Divider sx={{ pb: 2 }} />
            </Box>

            {/* Top row */}
            <Box
              sx={{
                alignItems: { xs: 'center' },
                justifyContent: 'space-between',
                gap: 4,
                flexDirection: { xs: 'column', xl: 'row' },
                display: 'flex',
              }}
            >
              {/* Concept */}
              <Box
                sx={{
                  alignItems: 'center',
                  gap: 2,
                  display: 'flex',
                  flexDirection: { xs: 'column', lg: 'row' },
                }}
              >
                <Box sx={{ width: 300 }}>
                  <Autocomplete
                    label="Grupo"
                    onChange={handleConceptGroupChange}
                    property="conceptGroupName"
                    query={conceptGroupQuery}
                    source={getConceptGroups}
                    value={expense.conceptGroup}
                  />
                </Box>

                {expense.conceptGroup && (
                  <Box sx={{ width: 300 }}>
                    <Autocomplete
                      label="Concepto"
                      onChange={handleConceptChange}
                      property="conceptName"
                      query={conceptQuery}
                      source={getConcepts}
                      value={expense.concept}
                    />
                  </Box>
                )}

                {!expense.concept && (
                  <Chip
                    color="error"
                    label={`Seleccionar ${
                      expense.conceptGroup ? 'concepto' : 'grupo'
                    }`}
                    size="small"
                  />
                )}
              </Box>

              {/* Summary */}
              <Box
                sx={{
                  flexDirection: { xs: 'column', md: 'row' },
                  gap: { xs: 2, lg: 4 },
                  display: 'flex',
                }}
              >
                <Summary loading={loading} title="Subtotal">
                  {expense.amount}
                </Summary>
                <Summary
                  color={
                    amountDistributed === expense.amount
                      ? 'success.main'
                      : 'error.main'
                  }
                  loading={loading}
                  title="Distribuido"
                >
                  {amountDistributed}
                </Summary>
                <Summary
                  color={amountLeft ? 'error.main' : 'success.main'}
                  loading={loading}
                  title="Faltante"
                >
                  {amountLeft}
                </Summary>
              </Box>
            </Box>

            {/* Table */}
            <Table
              columns={DISTRIBUTION_COLUMNS(handleDeleteRequest)}
              loading={loading}
              rows={distributions}
            />

            {/* Buttons */}
            <Box
              sx={{
                justifyContent: 'space-between',
                display: 'flex',
                width: '100%',
              }}
            >
              <Button color="error" onClick={() => exit()}>
                Regresar
              </Button>

              {loading ? (
                <Skeleton
                  sx={{ width: 120, height: 38 }}
                  variant="rectangular"
                />
              ) : (
                <Button
                  color={'primary'}
                  disabled={!amountLeft}
                  endIcon={<AddIcon />}
                  onClick={handleOpen}
                  sx={{ color: 'common.white' }}
                  variant="contained"
                >
                  Nueva
                </Button>
              )}
            </Box>
          </TableBox>
        </ContentBox>
      </ContentContainer>

      {/* New/edit Destribution Modal */}
      <Modal
        disabled={loading}
        open={open}
        onClose={handleClose}
        title="Nueva distribución"
        width="sm"
      >
        <Formik
          initialValues={DISTRIBUTION_INITIAL_VALUES}
          onSubmit={async values => {
            try {
              setLoading(true);

              const { zone, plaza, agent, chain, family, amount } = values;
              const data = {
                expense: expense._id,
                zone: zone?._id || null,
                plaza: plaza?._id || null,
                agent: agent?._id || null,
                chain: chain?._id || null,
                family: family._id,
                amount: Number(amount).toFixed(2),
              };

              await createDistribution(data);

              toast.success('Distribución agregada con éxito.');

              await fetchData();
              setOpen(false);
            } catch (error) {
              setLoading(false);

              if (error.status === 409) {
                setError(error.message);
                return;
              }

              if (error.status === 401) setUser(null);
              toast.error(error.message);
            }
          }}
          validationSchema={DISTRIBUTION_ADD_SCHEMA}
        >
          {({ values }) => (
            <Form>
              <Grid container spacing={2} sx={{ p: 2, mb: 4 }}>
                {error && (
                  <Grid item xs={12}>
                    <Alert
                      onClose={() => setError(null)}
                      severity="error"
                      sx={{
                        mb: 2,
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: 'error.dark',
                      }}
                    >
                      {error}
                    </Alert>
                  </Grid>
                )}

                {DISTRIBUTION_FIELDS.map(
                  ({ isDisabled, label, name, property, source }) => (
                    <Grid item xs={12} key={name}>
                      <FormAutocomplete
                        disabled={isDisabled(values) || loading}
                        disabledIcon={!loading}
                        label={label}
                        name={name}
                        onChange={newValue => handleQueryChange(name, newValue)}
                        property={property}
                        query={formQueries[name]}
                        source={source}
                      />
                    </Grid>
                  )
                )}

                <Grid item xs={12}>
                  <FormTextbox
                    currency
                    disabled={loading}
                    label="Cantidad"
                    name="amount"
                  />
                </Grid>
              </Grid>

              {/* Buttons */}
              <Box
                sx={{
                  justifyContent: 'space-between',
                  display: 'flex',
                  width: '100%',
                  px: 2,
                }}
              >
                <Button color="error" disabled={loading} onClick={handleClose}>
                  Cancelar
                </Button>

                <LoadingButton
                  disabled={values.amount <= 0 || values.amount > amountLeft}
                  icon={<AddIcon />}
                  loading={loading}
                  text={'Guardar'}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        open={openConfirmation}
        onClose={handleClose}
        title="Eliminar distribución"
        width="sm"
      >
        <>
          <Typography color="text.secondary" sx={{ mb: 2 }} variant="subtitle1">
            ¿Está seguro de eliminar la distribución? No hay vuelta atrás.
          </Typography>

          {/* Buttons */}
          <Box
            sx={{
              justifyContent: 'space-between',
              display: 'flex',
              width: '100%',
            }}
          >
            <Button color="error" disabled={loading} onClick={handleClose}>
              Cancelar
            </Button>

            <Button
              disabled={loading}
              endIcon={<DeleteIcon />}
              onClick={handleDelete}
              variant="contained"
            >
              Eliminar
            </Button>
          </Box>
        </>
      </Modal>
    </>
  );
};

Distributions.prototypes = {
  exit: PropTypes.func.isRequired,
  selectedExpense: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    supplier: PropTypes.object.isRequired,
    date: PropTypes.string.isRequired,
    invoice: PropTypes.number,
    description: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    distributions: PropTypes.arrayOf(PropTypes.string).isRequired,
    concept: PropTypes.object,
    isDeductible: PropTypes.bool.isRequired,
    isComplete: PropTypes.bool.isRequired,
  }).isRequired,
};

export default Distributions;
