import getError from '../getError';
import instance from '../instance';

export const getZones = async query => {
  try {
    const res = await instance.get('/zones', { params: query });
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error consultando zonas.');
    throw newError;
  }
};

export const createZone = async data => {
  try {
    const res = await instance.post('/zones', data);
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error creando zona.');
    throw newError;
  }
};

export const editZone = async data => {
  try {
    const res = await instance.put('/zones', data);
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error editando zona.');
    throw newError;
  }
};
