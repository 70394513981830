import * as Yup from 'yup';

const familyNum = Yup.number()
  .typeError('Número invalido.')
  .positive('Requiere ser positivo.')
  .integer('Requiere ser entero.')
  .required('Requerido.');

const familyName = Yup.string()
  .max(128, 'Requiere máximo 128 caracteres.')
  .notOneOf(['n/a', 'N/A'], 'Nombre invalido.')
  .trim()
  .required('Requerido.');

const chains = Yup.array()
  .of(Yup.object())
  .min(1, 'Requiere mínimo una familia.')
  .required('Requerido.');

const isActive = Yup.bool().required();

export const FAMILY_ADD_SCHEMA = Yup.object().shape({
  familyNum,
  familyName,
  chains,
});

export const FAMILY_EDIT_SCHEMA = Yup.object().shape({
  familyNum,
  familyName,
  chains,
  isActive,
});
