import { useState } from 'react';
import PropTypes from 'prop-types';
import { readFile, utils } from 'xlsx';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import UploadButton from './Components/UploadButton';

const UploadArea = ({ error, onLoad, setError }) => {
  const [isDrag, setIsDrag] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleReadExcel = async file => {
    try {
      setLoading(true);

      const data = await file.arrayBuffer();
      const workbook = readFile(data);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = utils.sheet_to_json(worksheet);
      await onLoad(jsonData);
    } catch (error) {
      setError(new Error('Error en la lectura de datos.'));
    }

    handleResultClear();
  };

  const handleResultClear = () => {
    setIsDrag(false);
    setLoading(false);
  };

  const handleReset = () => {
    setLoading(false);
    setError(null);
  };

  /* suppress default behavior for drag and drop */
  const suppress = e => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDragRead = e => {
    suppress(e);
    handleReadExcel(e.dataTransfer.files[0]);
  };

  const handleDragOver = e => {
    suppress(e);
    if (!error && !loading) setIsDrag(true);
  };

  const handleDragLeave = e => {
    suppress(e);
    setIsDrag(false);
  };

  return (
    <>
      {/* Box */}
      <Box
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDragRead}
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: 2,
          display: 'flex',
          boxShadow: 1,
          borderStyle: 'solid',
          borderColor: isDrag ? 'primary.main' : 'grey.300',
          borderWidth: 1,
          borderRadius: 1,
          height: '100%',
          backgroundColor: 'white',
        }}
      >
        <UploadButton
          error={error}
          isDrag={isDrag}
          loading={loading}
          onErrorClick={handleReset}
          onFileSelected={e => handleReadExcel(e.target.files[0])}
        />

        {/* Text */}
        <Box sx={{ textAlign: 'center', pointerEvents: 'none' }}>
          {/* Top text */}
          <Box
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
              display: 'flex',
              mb: 1,
            }}
          >
            {error && <ErrorOutlineIcon color="error" />}

            <Typography
              display="inline"
              fontWeight={error ? 'medium' : 'regular'}
              variant="subtitle1"
            >
              {error
                ? error.message
                : 'Arrastrar o hacer clic en el icono para cargar un documento.'}
            </Typography>
          </Box>

          {/* Bottom text */}
          <Typography variant="body2">
            {error
              ? 'Hacer clic en el icono para intentar de nuevo.'
              : '.csv, .xls, .xlsx'}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

UploadArea.propTypes = {
  error: PropTypes.instanceOf(Error),
  onLoad: PropTypes.func.isRequired,
  setError: PropTypes.func,
};
export default UploadArea;
