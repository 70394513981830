import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { DateRange } from 'react-date-range';
import { es } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import '../styles.css';

import { useModal } from '../../../hooks';
import { formatDate } from '../../../utils';

const DateRangePicker = props => {
  const {
    alignment = 'center',
    dates,
    dateState,
    handleDatePick,
    setDateState,
  } = props;

  const [open, setOpen, modalRef] = useModal({ ref: true });

  const calculateDates = ({ startDate, endDate }) => {
    const dates = [];
    let current = new Date(startDate.getTime());

    while (current <= endDate) {
      dates.push(new Date(current));
      current.setDate(current.getDate() + 1);
    }

    return dates;
  };

  const isDateEqual = (date1, date2) => date1.getTime() === date2.getTime();

  return (
    <>
      <div className="box">
        <input
          className="inputBox"
          onClick={() => setOpen(!open)}
          placeholder="Fechas"
          readOnly
          style={{ borderColor: open ? '#13322B' : '#CCC', height: 56 }}
          value={
            dates.length > 0
              ? isDateEqual(dateState[0].startDate, dateState[0].endDate)
                ? `${formatDate(dateState[0].startDate)}`
                : `${formatDate(dateState[0].startDate)} al ${formatDate(
                    dateState[0].endDate
                  )}`
              : ''
          }
        />

        <div ref={modalRef}>
          {dates.length < 2 && open && (
            <DateRange
              className={`calendarBox calendarBox__${alignment}`}
              dateDisplayFormat="dd/MM/yyyy"
              locale={es}
              maxDate={new Date()}
              onChange={item => {
                setDateState([item.selection]);

                const dates = calculateDates(item.selection);

                if (dates.length <= 31) handleDatePick(dates);
                else toast.error('Rango excede máximo. Intente de nuevo.');
              }}
              rangeColors={['#13322B']}
              ranges={dateState}
            />
          )}
        </div>
      </div>
    </>
  );
};

DateRangePicker.propTypes = {
  alignment: PropTypes.oneOf(['center', 'left', 'right']),
  dates: PropTypes.array.isRequired,
  dateState: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleDatePick: PropTypes.func.isRequired,
  setDateState: PropTypes.func.isRequired,
};

export default DateRangePicker;
