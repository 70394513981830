import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const FormCheckbox = props => {
  const { disabled, name, trueLabel, falseLabel } = props;

  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={field.value}
          onChange={(_, newValue) => setFieldValue(name, newValue)}
        />
      }
      disabled={disabled}
      label={field.value ? trueLabel : falseLabel}
    />
  );
};

FormCheckbox.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  trueLabel: PropTypes.string.isRequired,
  falseLabel: PropTypes.string.isRequired,
};

export default FormCheckbox;
