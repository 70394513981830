import PropTypes from 'prop-types';
import { useState } from 'react';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import { LOCALE_TEXT } from './constants';

const Table = props => {
  const { columns, getId = '_id', loading, rows, sort, sortField } = props;

  const [pageSize, setPageSize] = useState(10);

  return (
    <DataGrid
      columns={columns}
      components={{ Toolbar: GridToolbar }}
      disableDensitySelector
      disableSelectionOnClick
      getRowId={row => row[getId]}
      initialState={{
        sorting: {
          sortModel: [{ field: sortField, sort }],
        },
      }}
      loading={!rows || loading}
      localeText={LOCALE_TEXT}
      onPageSizeChange={newPageSize => setPageSize(newPageSize)}
      pageSize={pageSize}
      rows={rows || []}
      rowsPerPageOptions={[10, 25, 50]}
      sx={{
        boxShadow: 1,
        height: '100%',
        minHeight: 400,
        bgcolor: '#FFF',
      }}
    />
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  getId: PropTypes.string,
  loading: PropTypes.bool,
  rows: PropTypes.arrayOf(PropTypes.object),
  sort: PropTypes.oneOf(['asc', 'desc']),
  sortField: PropTypes.string,
};

export default Table;
