import PropTypes from 'prop-types';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = props => {
  const { position = 'bottom' } = props;

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: position,
      },
    },
  };

  return !props.data ? (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        gap: 1,
      }}
    >
      <ErrorOutlineIcon color="error" />
      <Typography variant="h5" color="error">
        No se encontraron resultados.
      </Typography>
    </Box>
  ) : (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        backgroundColor: '#FFF',
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '80%',
          width: '100%',
        }}
      >
        <Pie options={options} data={props.data} />
      </Box>
    </Box>
  );
};

export default PieChart;

PieChart.propTypes = {
  position: PropTypes.oneOf(['bottom', 'top', 'right', 'left']),
  data: PropTypes.shape({
    labels: PropTypes.array.isRequired,
    datasets: PropTypes.arrayOf(PropTypes.object).isRequired,
  }),
};
