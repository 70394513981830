import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const ExpenseCount = props => {
  const { icon: Icon } = props;

  return (
    <IconButton
      onClick={props.onClick}
      sx={{ background: props.selected ? 'rgba(0,0,0,0.1)' : 'inherit' }}
    >
      <Tooltip arrow placement="top" title={props.title}>
        <Box
          sx={{
            display: 'flex ',
            gap: 0.5,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Icon fontSize="small" sx={{ color: props.color }} />
          <Typography variant="h6" sx={{ color: props.color }}>
            {props.children}
          </Typography>
        </Box>
      </Tooltip>
    </IconButton>
  );
};

ExpenseCount.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  children: PropTypes.number.isRequired,
};

export default ExpenseCount;
