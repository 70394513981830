import DeleteIcon from '@mui/icons-material/Delete';
import { GridActionsCellItem } from '@mui/x-data-grid';

import {
  excelDateToDate,
  formatCurrency,
  formatDate,
  currencyComparator,
} from '../../../utils';

export const ADD_DE_EXCEL_REQUIRED_HEADERS = {
  amount: 'SubTotal',
  date: 'Emisión',
  description: 'Conceptos Descripción',
  supplierRfc: 'Emisor RFC',
  uuid: 'UUID',
  status: 'Estatus',
};

export const ADD_DE_EXCEL_OPTIONAL_HEADERS = {
  invoice: 'Folio',
};

export const ADD_DE_EXCEL_STATUS_VALID = 'Vigente';

const ADD_DE_COLUMNS = handleDelete => [
  {
    field: ADD_DE_EXCEL_REQUIRED_HEADERS.uuid,
    headerName: 'UUID',
    hide: true,
    minWidth: 320,
    flex: 3,
  },
  {
    field: ADD_DE_EXCEL_OPTIONAL_HEADERS.invoice,
    headerName: 'Folio',
    minWidth: 80,
    flex: 1,
    type: 'number',
    valueGetter: params =>
      params.row[ADD_DE_EXCEL_OPTIONAL_HEADERS.invoice] || 'N/A',
  },
  {
    field: ADD_DE_EXCEL_REQUIRED_HEADERS.date,
    headerName: 'Emisión',
    minWidth: 100,
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    valueGetter: params => formatDate(excelDateToDate(params.row.Emisión)),
  },
  {
    field: ADD_DE_EXCEL_REQUIRED_HEADERS.supplierRfc,
    headerName: 'Emisor RFC',
    minWidth: 140,
    flex: 1,
  },
  {
    field: 'supplierName',
    headerName: 'Emisor Nombre',
    minWidth: 240,
    flex: 3,
  },
  {
    field: ADD_DE_EXCEL_REQUIRED_HEADERS.description,
    headerName: 'Conceptos Descripción',
    minWidth: 350,
    flex: 5,
  },
  {
    field: ADD_DE_EXCEL_REQUIRED_HEADERS.amount,
    headerName: 'Subtotal',
    type: 'number',
    minWidth: 120,
    flex: 2,
    valueGetter: params => formatCurrency(params.row.SubTotal),
    sortComparator: currencyComparator,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: 'Acciones',
    flex: 1,
    minWidth: 100,
    align: 'center',
    getActions: params => [
      <GridActionsCellItem
        icon={<DeleteIcon />}
        label="Eliminar"
        onClick={() => handleDelete(params)}
      />,
    ],
  },
];

export default ADD_DE_COLUMNS;
