import { Navigate, Outlet } from 'react-router-dom';

import Layout from '../Components/Layout';
import Spinner from './components/Spinner';

import useAuth from '../context/useAuth';

const PrivateRoute = () => {
  const { user, loading } = useAuth();

  if (loading) return <Spinner />;

  return user ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to="/login" replace />
  );
};

export default PrivateRoute;
