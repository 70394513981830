import getError from '../getError';
import instance from '../instance';

export const getUsers = async query => {
  try {
    const res = await instance.get('/users', { params: query });
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error consultando usuarios.');
    throw newError;
  }
};

export const createUser = async data => {
  try {
    const res = await instance.post('/users', data);
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error creando usuario.');
    throw newError;
  }
};

export const editUser = async data => {
  try {
    const res = await instance.put('/users', data);
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error editando usuario.');
    throw newError;
  }
};
