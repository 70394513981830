import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';

import Autocomplete from '../../Autocomplete';

const FormAutocomplete = props => {
  const {
    disabled,
    disabledIcon,
    groupBy,
    label,
    multiple,
    name,
    onChange,
    property,
    source,
    query,
  } = props;

  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const formConfig = {};

  if (meta.error && meta.touched) {
    formConfig.error = true;
    formConfig.helperText = meta.error;
  }

  return (
    <Autocomplete
      disabled={disabled}
      disabledIcon={disabledIcon}
      formConfig={formConfig}
      groupBy={groupBy}
      label={label}
      multiple={multiple}
      onChange={newValue => {
        setFieldValue(name, newValue);
        onChange && onChange(newValue);
      }}
      property={property}
      query={query}
      source={source}
      value={field.value}
    />
  );
};

FormAutocomplete.propTypes = {
  disabled: PropTypes.bool,
  disabledIcon: PropTypes.bool,
  groupBy: PropTypes.func,
  label: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  property: PropTypes.string.isRequired,
  source: PropTypes.func.isRequired,
  query: PropTypes.object,
};

export default FormAutocomplete;
