import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';
import { Autocomplete as MuiAutocomplete } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import useAuth from '../../context/useAuth';

const Autocomplete = props => {
  const {
    disabled,
    disabledIcon,
    formConfig,
    groupBy,
    label,
    multiple,
    onChange,
    property,
    query,
    source,
    value,
  } = props;

  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);

  const { setUser } = useAuth();

  useEffect(() => {
    if (disabled) setLoading(false);
    else
      (async () => {
        try {
          setLoading(true);
          setOptions([]);

          const res = await source(query);

          setOptions(res);
        } catch (error) {
          if (error.status === 401) setUser(null);
          toast.error(error.message);
        } finally {
          setLoading(false);
        }
      })();
  }, [source, query, disabled, setUser]);

  return (
    <MuiAutocomplete
      disabled={disabled}
      fullWidth
      getOptionLabel={option => option[property]}
      groupBy={groupBy}
      isOptionEqualToValue={(option, value) =>
        option[property] === value[property]
      }
      loading={loading}
      loadingText="Cargando..."
      multiple={multiple}
      noOptionsText="Sin opciones."
      onChange={(_, newValue) => onChange(newValue)}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      options={options}
      renderInput={params => (
        <TextField
          {...params}
          {...formConfig}
          label={label}
          sx={{ '& .MuiInputBase-root': { bgcolor: '#FFF' } }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && <CircularProgress color="inherit" size={20} />}
                {disabled && disabledIcon && (
                  <LockOutlinedIcon color="inherit" size={20} />
                )}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      value={value}
    />
  );
};

Autocomplete.propTypes = {
  disabled: PropTypes.bool,
  disabledIcon: PropTypes.bool,
  formConfig: PropTypes.object,
  groupBy: PropTypes.func,
  label: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  property: PropTypes.string.isRequired,
  query: PropTypes.object,
  source: PropTypes.func.isRequired,
  value: PropTypes.any,
};

export default Autocomplete;
