import { useState } from 'react';
import { toast } from 'react-toastify';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import DateRangePicker from '../../Components/dates/DateRangePicker';
import LoadingButton from '../../Components/LoadingButton';
import { exportExcel } from '../../utils';
import {
  getDistributionDetailExport,
  getDistributionSummaryExport,
  getExpenseExport,
} from '../../api/services';
import useAuth from '../../context/useAuth';

const Export = () => {
  const [dates, setDates] = useState([]);
  const [dateState, setDateState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [loading, setLoading] = useState(false);

  const { setUser } = useAuth();

  const handleExport = async () => {
    try {
      setLoading(true);

      const [resExport, resDistributionSummary, resDistributionDetail] =
        await Promise.all([
          await getExpenseExport(dates),
          await getDistributionSummaryExport(dates),
          await getDistributionDetailExport(dates),
        ]);

      if (resExport.length <= 0) {
        toast.info('No se encontraron gastos.');
      } else {
        exportExcel(
          [
            [resExport, 'Gastos'],
            [resDistributionSummary, 'Distribuciones Resumen'],
            [resDistributionDetail, 'Distribuciones Detalle'],
          ],
          'Gastos-distribución'
        );

        toast.success('Se ha exportado con éxito.');
      }

      handleClean();
    } catch (error) {
      if (error.status === 401) setUser(null);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleClean = () => {
    setDates([]);
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 6 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            width: 250,
          }}
        >
          <Box sx={{ display: 'flex', gap: 2 }}>
            <DateRangePicker
              alignment="center"
              dates={dates}
              dateState={dateState}
              handleDatePick={val => setDates(val)}
              setDateState={setDateState}
            />

            {dates.length > 0 && (
              <Button onClick={handleClean} disabled={loading}>
                Limpiar
              </Button>
            )}
          </Box>

          <LoadingButton
            disabled={dates.length <= 1}
            icon={<FileDownloadIcon />}
            loading={loading}
            onClick={handleExport}
            text="Exportar"
            type="button"
          >
            Exportar
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
};

export default Export;
