import { BrowserRouter, Route, Routes } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import RoleRouter from './RoleRouter';

import AddExpenses from '../Pages/AddExpenses';
import Agents from '../Pages/Agents';
import Chains from '../Pages/Chains';
import ConceptGroups from '../Pages/ConceptGroups';
import Concepts from '../Pages/Concepts';
import Departments from '../Pages/Departments';
import Expenses from '../Pages/Expenses';
import Export from '../Pages/Export';
import Dashboard from '../Pages/Dashboard';
import Families from '../Pages/Families';
import Home from '../Pages/Home';
import Login from '../Pages/Login';
import NotFound from '../Pages/NotFound';
import Participation from '../Pages/Participation';
import Plazas from '../Pages/Plazas';
import Suppliers from '../Pages/Suppliers';
import Users from '../Pages/Users';
import Zones from '../Pages/Zones';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />

        <Route path="/" element={<PrivateRoute />}>
          <Route index element={<Home />} />
          <Route
            path="/dashboard"
            element={
              <RoleRouter roles={['USER']}>
                <Dashboard />
              </RoleRouter>
            }
          />
          <Route
            path="/agregar"
            element={
              <RoleRouter roles={['USER']}>
                <AddExpenses />
              </RoleRouter>
            }
          />
          <Route
            path="/gastos"
            element={
              <RoleRouter roles={['USER', 'ADMIN']}>
                <Expenses />
              </RoleRouter>
            }
          />
          <Route
            path="/usuarios"
            element={
              <RoleRouter roles={['ADMIN']}>
                <Users />
              </RoleRouter>
            }
          />
          <Route
            path="/departamentos"
            element={
              <RoleRouter roles={['ADMIN']}>
                <Departments />
              </RoleRouter>
            }
          />
          <Route
            path="/grupos"
            element={
              <RoleRouter roles={['ADMIN']}>
                <ConceptGroups />
              </RoleRouter>
            }
          />
          <Route
            path="/conceptos"
            element={
              <RoleRouter roles={['ADMIN']}>
                <Concepts />
              </RoleRouter>
            }
          />
          <Route
            path="/proveedores"
            element={
              <RoleRouter roles={['ADMIN']}>
                <Suppliers />
              </RoleRouter>
            }
          />
          <Route
            path="/zonas"
            element={
              <RoleRouter roles={['ADMIN']}>
                <Zones />
              </RoleRouter>
            }
          />
          <Route
            path="/plazas"
            element={
              <RoleRouter roles={['ADMIN']}>
                <Plazas />
              </RoleRouter>
            }
          />
          <Route
            path="/agentes"
            element={
              <RoleRouter roles={['ADMIN']}>
                <Agents />
              </RoleRouter>
            }
          />
          <Route
            path="/cadenas"
            element={
              <RoleRouter roles={['ADMIN']}>
                <Chains />
              </RoleRouter>
            }
          />
          <Route
            path="/familias"
            element={
              <RoleRouter roles={['ADMIN']}>
                <Families />
              </RoleRouter>
            }
          />
          <Route
            path="/participacion"
            element={
              <RoleRouter roles={['ADMIN']}>
                <Participation />
              </RoleRouter>
            }
          />
          <Route
            path="/exportar"
            element={
              <RoleRouter roles={['ANALYST']}>
                <Export />
              </RoleRouter>
            }
          />
          <Route path="/*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
