import getError from '../getError';
import instance from '../instance';

export const getPlazas = async query => {
  try {
    const res = await instance.get('/plazas', { params: query });
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error consultando plazas.');
    throw newError;
  }
};

export const createPlaza = async data => {
  try {
    const res = await instance.post('/plazas', data);
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error creando plaza.');
    throw newError;
  }
};

export const editPlaza = async data => {
  try {
    const res = await instance.put('/plazas', data);
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error editando plaza.');
    throw newError;
  }
};
