import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import LineChart from '../../Components/charts/LineChart';
import PieChart from '../../Components/charts/PieChart';
import { ContentBox, ContentContainer } from '../../theme';
import { getDashboard } from '../../api/services';
import useAuth from '../../context/useAuth';

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [value, setValue] = useState(0);

  const handleTabChange = (_, newValue) => {
    setValue(newValue);
  };

  const { user, setUser } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getDashboard({
          department: user.department?._id,
        });

        setData(res);

        setLoading(false);
      } catch (error) {
        if (error.status === 401) setUser(null);
        toast.error(error.message);
      }
    };

    fetchData();
  }, [user.department, setUser]);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <ContentContainer maxWidth="xl">
          <ContentBox>
            <Tabs onChange={handleTabChange} value={value} variant="fullWidth">
              <Tab label="Proveedor" />
              <Tab label="Zona" />
              <Tab label="Plaza" />
              <Tab label="Cadena" />
              <Tab label="Familia" />
              <Tab label="% Tipo" />
            </Tabs>

            <Box
              sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {value === 0 && <LineChart data={data.supplierChart} />}

              {value === 1 && <LineChart data={data.zoneChart} />}

              {value === 2 && <LineChart data={data.plazaChart} />}

              {value === 3 && <LineChart data={data.chainChart} />}

              {value === 4 && <LineChart data={data.familyChart} />}

              {value === 5 && <PieChart data={data.typePercentageChart} />}
            </Box>
          </ContentBox>
        </ContentContainer>
      )}
    </>
  );
};

export default Dashboard;
