import { useState } from 'react';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import LoginIcon from '@mui/icons-material/Login';
import Typography from '@mui/material/Typography';

import FormTextbox from '../../Components/forms/FormTextbox';
import LoadingButton from '../../Components/LoadingButton';
import { LOGIN_INITIAL_VALUES } from '../../constants/forms/initialValues';
import { LOGIN_SCHEMA } from '../../constants/forms/schemas';
import { login } from '../../api/services';
import useAuth from '../../context/useAuth';

const Login = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const { setUser } = useAuth();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100vh',
        backgroundColor: 'common.white',
      }}
    >
      <Box
        component="img"
        src="images/home.svg"
        sx={{
          position: 'absolute',
          opacity: 0.035,
          height: '60vh',
          userSelect: 'none',
        }}
      />

      <Container maxWidth="xs">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 6,
            textAlign: 'center',
          }}
        >
          <Typography variant="h4">Distribución de gastos</Typography>
          <Formik
            initialValues={LOGIN_INITIAL_VALUES}
            onSubmit={async values => {
              try {
                setLoading(true);

                const user = await login(values);
                setUser(user);
              } catch (error) {
                if (error.status === 401) {
                  setError(error.message);
                  return;
                }

                toast.error(error.message);
              } finally {
                setLoading(false);
              }
            }}
            validationSchema={LOGIN_SCHEMA}
          >
            {props => (
              <Form>
                <Grid container spacing={2}>
                  {error && (
                    <Grid item xs={12}>
                      <Alert
                        onClose={() => setError(null)}
                        severity="error"
                        sx={{
                          mb: 2,
                          borderWidth: 1,
                          borderStyle: 'solid',
                          borderColor: 'error.dark',
                        }}
                      >
                        {error}
                      </Alert>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <FormTextbox
                      disabled={loading}
                      label="Usuario"
                      name="username"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormTextbox
                      password
                      disabled={loading}
                      label="Contraseña"
                      name="password"
                      type="password"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <LoadingButton
                      icon={<LoginIcon />}
                      loading={loading}
                      text="Entrar"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Container>

      <Box sx={{ position: 'absolute', bottom: 10 }}>
        <Typography variant="caption">
          © Copyright 2023. Derechos reservados por Bomuca.
        </Typography>
      </Box>
    </Box>
  );
};

export default Login;
