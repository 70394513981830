import React, { createContext, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { me } from '../api/services/auth';

const AuthContext = createContext({
  user: null,
  loading: true,
  setUser: () => {},
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function () {
      try {
        const user = await me();

        if (user) {
          setUser(user);
        } else {
          setUser(null);
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const values = {
    user,
    loading,
    setUser,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  return useContext(AuthContext);
};

export default useAuth;
