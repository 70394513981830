import { utils, writeFile } from 'xlsx';

const exportExcel = (wsData, wbName) => {
  const wb = utils.book_new();

  wsData.forEach(([data, wsName]) => {
    const ws = utils.json_to_sheet(data);
    utils.book_append_sheet(wb, ws, wsName);
  });

  writeFile(wb, `${wbName}.xlsx`);
};

export default exportExcel;
