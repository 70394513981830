import { createTheme } from '@mui/material/styles';
import { styled } from '@mui/system';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

// Theme
const defaultTheme = createTheme();

let theme = createTheme({
  palette: {
    primary: {
      lightest: '#D3EEE8',
      main: '#13322B',
    },
    secondary: {
      lightest: '#FFE1C2',
      main: '#FF8200',
      darkest: '#663500',
    },
    error: {
      lightest: '#FCC5C5',
      main: '#B80C09',
      darkest: '#750706',
    },
    success: {
      lightest: '#C4FDDE',
      main: '#058C42',
      darkest: '#034F25',
    },
    common: {
      white: '#F1F1F1',
      black: '#212322',
    },
    contrastThreshold: 7,
  },

  typography: {
    button: {
      fontWeight: defaultTheme.typography.fontWeightBold,
    },
  },
});

theme = createTheme(theme, {
  palette: {
    primary: {
      contrastText: theme.palette.common.white,
    },
    background: {
      default: theme.palette.common.white,
    },
  },

  components: {
    MuiChip: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: theme.palette.primary.lightest,
          color: theme.palette.primary.main,
        },
        colorSecondary: {
          backgroundColor: theme.palette.secondary.lightest,
          color: theme.palette.secondary.darkest,
        },
        colorError: {
          backgroundColor: theme.palette.error.lightest,
          color: theme.palette.error.darkest,
        },
        colorSuccess: {
          backgroundColor: theme.palette.success.lightest,
          color: theme.palette.success.darkest,
        },
      },
    },
  },
});

export default theme;

// Styled components

export const ContentContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
}));

export const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '90%',
  width: '100%',
}));

export const TableBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  height: '100%',
}));
