import * as Yup from 'yup';

const zoneNum = Yup.number()
  .typeError('Número invalido.')
  .positive('Requiere ser positivo.')
  .integer('Requiere ser entero.')
  .required('Requerido.');

const zoneName = Yup.string()
  .max(128, 'Requiere máximo 128 caracteres.')
  .notOneOf(['n/a', 'N/A'], 'Nombre invalido.')
  .trim()
  .required('Requerido.');

const isActive = Yup.bool().required();

export const ZONE_ADD_SCHEMA = Yup.object().shape({
  zoneNum,
  zoneName,
});

export const ZONE_EDIT_SCHEMA = Yup.object().shape({
  zoneNum,
  zoneName,
  isActive,
});
