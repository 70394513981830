import getError from '../getError';
import instance from '../instance';

export const getExpenses = async query => {
  try {
    const res = await instance.get('/expenses', { params: query });
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error consultando gastos.');
    throw newError;
  }
};

export const createDeductibleExpenses = async data => {
  try {
    const res = await instance.post('/expenses/deductibles', data);
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error creando gastos.');
    throw newError;
  }
};

export const createNonDeductibleExpense = async data => {
  try {
    const res = await instance.post('/expenses/non-deductible', data);
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error creando gasto.');
    throw newError;
  }
};

export const editExpenseConceptGroup = async (expense, conceptGroup) => {
  try {
    const res = await instance.put('/expenses/concept-group', {
      expense,
      conceptGroup,
    });
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error modificando grupo.');
    throw newError;
  }
};

export const editExpenseConcept = async (expense, concept) => {
  try {
    const res = await instance.put('/expenses/concept', {
      expense,
      concept,
    });
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error modificando concepto.');
    throw newError;
  }
};

export const deleteExpense = async id => {
  try {
    const res = await instance.delete('/expenses', { params: id });
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error borrando gasto.');
    throw newError;
  }
};
