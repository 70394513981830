import { useRef, useState, useEffect } from 'react';

const useModal = ({ ref }) => {
  const [open, setOpen] = useState(false);

  const modalRef = useRef(null);

  //   Hide on escape when ref
  useEffect(() => {
    const hideOnEscape = e => {
      if (e.key === 'Escape') setOpen(false);
    };

    if (ref) {
      document.addEventListener('keydown', hideOnEscape, true);
      return () => document.removeEventListener('keydown', hideOnEscape, true);
    }
  }, [setOpen, ref]);

  //   Hide on click outside when ref
  useEffect(() => {
    const hideOnClickOutside = e => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        setOpen(false);
      }
    };

    if (ref) {
      document.addEventListener('click', hideOnClickOutside, true);
      return () =>
        document.removeEventListener('click', hideOnClickOutside, true);
    }
  }, [setOpen, ref]);

  return [open, setOpen, modalRef];
};

export default useModal;
