import PropTypes from 'prop-types';
import { useState } from 'react';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import Delete from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import ListIcon from '@mui/icons-material/List';

import ExpenseDetail from '../ExpenseDetail';

import { formatCurrency, formatDate } from '../../../../utils';
import useAuth from '../../../../context/useAuth';

const ExpenseCard = props => {
  const { expense, id, onDelete, onSelect } = props;

  const [copyToClipboardText, setCopyToClipboardText] = useState('Copiar ID');

  const { user } = useAuth();

  const {
    _id,
    supplier,
    date,
    invoice,
    description,
    isDeductible,
    amount,
    isComplete,
  } = expense;

  const getAvatar = () => {
    let color = '';
    let icon = null;

    const handleSucces = () => {
      color = 'success.main';
      icon = <CheckIcon />;
    };

    const handleIncomplete = () => {
      color = 'error.main';
      icon = <CloseIcon />;
    };

    isComplete ? handleSucces() : handleIncomplete();

    return <Avatar sx={{ bgcolor: color }}>{icon}</Avatar>;
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(_id);
    setCopyToClipboardText('ID copiado a portapapeles.');

    setTimeout(() => setCopyToClipboardText('Copiar ID'), 2000);
  };

  return (
    <Card sx={{ maxWidth: 330, backgroundColor: '#FFF' }}>
      {/* Header */}
      <CardHeader
        avatar={getAvatar()}
        subheader={formatDate(date)}
        title={
          <ExpenseDetail
            content={supplier.supplierName}
            contentColor="text.primary"
            placement="top"
            tooltip
          />
        }
      />

      {/* Content */}
      <CardContent>
        <Box sx={{ mb: 4 }} textAlign="center">
          <Typography
            color="text.primary"
            fontWeight="medium"
            variant="overline"
          >
            Folio:
          </Typography>

          <Typography color="text.secondary" fontWeight="medium" variant="h5">
            {invoice || 'N/A'}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {[
            ['Descripción', description],
            ['Tipo', isDeductible ? 'Deducible' : 'No deducible'],
          ].map(([title, content], i) => (
            <ExpenseDetail
              content={content}
              key={i}
              placement="top"
              title={title}
              tooltip={title === 'Descripción'}
            />
          ))}
        </Box>

        <Box sx={{ pt: 2 }} textAlign="center">
          <Chip color="secondary" label={formatCurrency(amount)} size="small" />
        </Box>
      </CardContent>

      {/* Actions */}
      <CardActions disableSpacing>
        {(user.role === 'USER' || user.role === 'SUPERADMIN') && (
          <Tooltip arrow placement="right" title="Ir a distribuciones">
            <IconButton onClick={() => onSelect(expense)}>
              <ListIcon />
            </IconButton>
          </Tooltip>
        )}

        {(user.role === 'ADMIN' || user.role === 'SUPERADMIN') && id && (
          <Tooltip arrow placement="right" title="Borrar">
            <IconButton onClick={() => onDelete(expense)}>
              <Delete />
            </IconButton>
          </Tooltip>
        )}

        <Tooltip arrow title={copyToClipboardText} placement="left">
          <IconButton
            onClick={handleCopyToClipboard}
            sx={{ marginLeft: 'auto' }}
          >
            <ContentCopyOutlinedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  );
};

ExpenseCard.propTypes = {
  expense: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    supplier: PropTypes.object.isRequired,
    date: PropTypes.string.isRequired,
    invoice: PropTypes.number,
    description: PropTypes.string.isRequired,
    distributions: PropTypes.arrayOf(PropTypes.string),
    amount: PropTypes.number.isRequired,
    conceptGroup: PropTypes.object,
    isDeductible: PropTypes.bool.isRequired,
    isComplete: PropTypes.bool.isRequired,
  }).isRequired,
  id: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  role: PropTypes.oneOf(['USER', 'ADMIN', 'ANALYST', 'SUPERADMIN']),
};

export default ExpenseCard;
