import * as Yup from 'yup';

const username = Yup.number()
  .typeError('Número invalido.')
  .positive('Requiere ser positivo.')
  .integer('Requiere ser entero.')
  .required('Requerido.');

const password = required =>
  Yup.string()
    .min(8, 'Requiere mínimo 8 caracteres.')
    .max(128, 'Requiere máximo 128 caracteres.')
    .trim()
    .when((_, schema) => (required ? schema.required('Requerido.') : schema));

const firstName = Yup.string()
  .max(128, 'Requiere máximo 128 caracteres.')
  .notOneOf(['n/a', 'N/A'], 'Nombre invalido.')
  .trim()
  .required('Requerido.');

const lastName = Yup.string()
  .max(128, 'Requiere máximo 128 caracteres.')
  .notOneOf(['n/a', 'N/A'], 'Apellido invalido.')
  .trim()
  .required('Requerido.');

const email = Yup.string().email('Correo inválido.').required('Requerido.');

const role = Yup.string()
  .oneOf(['USER', 'ANALYST', 'ADMIN'])
  .required('Requerido.');

const department = Yup.object()
  .when('role', (role, schema) => {
    if (role === 'USER') {
      return schema.required('Requerido.');
    }
  })
  .nullable();

const isActive = Yup.bool().required();

export const USER_ADD_SCHEMA = Yup.object().shape({
  username,
  password: password(true),
  firstName,
  lastName,
  email,
  role,
  department,
});

export const USER_EDIT_SCHEMA = changePassword =>
  Yup.object().shape({
    username,
    password: password(changePassword),
    firstName,
    lastName,
    email,
    role,
    department,
    isActive,
  });
