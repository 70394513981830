const generateYears = () => {
  const years = [];
  const max = new Date().getFullYear() + 1;
  const min = max - 4;

  for (let i = max; i >= min; i--) {
    years.push({ value: i, label: i.toString() });
  }

  return years;
};

export const YEARS = generateYears();
