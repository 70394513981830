import * as Yup from 'yup';

const departmentName = Yup.string()
  .max(128, 'Requiere máximo 128 caracteres.')
  .notOneOf(['n/a', 'N/A'], 'Nombre invalido.')
  .trim()
  .required('Requerido.');

const isActive = Yup.bool().required();

export const DEPARTMENT_ADD_SCHEMA = Yup.object().shape({
  departmentName,
});

export const DEPARTMENT_EDIT_SCHEMA = Yup.object().shape({
  departmentName,
  isActive,
});
