import EditIcon from '@mui/icons-material/Edit';
import { GridActionsCellItem } from '@mui/x-data-grid';

const DEPARTMENT_COLUMNS = handleEdit => [
  {
    field: 'departmentName',
    headerName: 'Departamento',
    flex: 4,
    minWidth: 200,
  },
  {
    field: 'isActive',
    headerName: 'Activo',
    type: 'boolean',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: 'Acciones',
    flex: 1,
    minWidth: 100,
    align: 'center',
    getActions: params => [
      <GridActionsCellItem
        icon={<EditIcon />}
        label="Editar"
        onClick={() => handleEdit(params)}
      />,
    ],
  },
];

export default DEPARTMENT_COLUMNS;
