import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import { Calendar } from 'react-date-range';
import { es } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import TextField from '@mui/material/TextField';

import { useModal } from '../../../hooks';
import { formatDate } from '../../../utils';

import '../../dates/styles.css';

const FormDatePicker = ({ name, loading }) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const [open, setOpen, modalRef] = useModal({ ref: true });

  const formConfig = {};

  if (meta.error && meta.touched) {
    formConfig.error = true;
    formConfig.helperText = meta.error;
  }

  return (
    <>
      <div className="box boxForm">
        <TextField
          {...formConfig}
          autoComplete="off"
          focused={open}
          fullWidth
          label="Fecha"
          sx={{ '& .MuiInputBase-root': { bgcolor: '#FFF' } }}
          onClick={() => {
            if (!loading) {
              setOpen(!open);
            }
          }}
          disabled={loading}
          value={formatDate(field.value)}
        />

        <div ref={modalRef}>
          {open && (
            <Calendar
              locale={es}
              dateDisplayFormat="dd/MM/yyyy"
              onChange={(date) => setFieldValue(name, date)}
              maxDate={new Date()}
              color="#13322B"
              date={field.value}
              className="calendarBox"
            />
          )}
        </div>
      </div>
    </>
  );
};

FormDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

export default FormDatePicker;
