import EditIcon from '@mui/icons-material/Edit';
import { GridActionsCellItem } from '@mui/x-data-grid';

const CHAIN_COLUMNS = handleEdit => [
  {
    field: 'chainNum',
    headerName: 'Número',
    maxWidth: 100,
    type: 'number',
  },
  {
    field: 'chainName',
    headerName: 'Nombre',
    flex: 4,
    minWidth: 200,
  },
  {
    field: 'agents',
    headerName: 'Agentes',
    flex: 2,
    minWidth: 200,
    align: 'center',
    headerAlign: 'center',
    valueGetter: params =>
      params.row.agents.map(agent => agent.agentName).join(', '),
  },
  {
    field: 'isActive',
    headerName: 'Activo',
    type: 'boolean',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: 'Acciones',
    flex: 1,
    minWidth: 100,
    align: 'center',
    getActions: params => [
      <GridActionsCellItem
        icon={<EditIcon />}
        label="Editar"
        onClick={() => handleEdit(params)}
      />,
    ],
  },
];

export default CHAIN_COLUMNS;
