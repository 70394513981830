import PropTypes from 'prop-types';

import { LoadingButton as MuiLoadingButton } from '@mui/lab';

const LoadingButton = props => {
  const {
    disabled,
    icon,
    loading,
    onClick,
    text,
    fullWidth = false,
    type = 'submit',
  } = props;

  return (
    <MuiLoadingButton
      disabled={disabled}
      endIcon={icon}
      loading={loading}
      loadingPosition="end"
      onClick={onClick}
      type={type}
      variant="contained"
      fullWidth={fullWidth}
    >
      {text}
    </MuiLoadingButton>
  );
};

LoadingButton.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.element.isRequired,
  loading: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['submit', 'button']),
  fullWidth: PropTypes.bool,
};

export default LoadingButton;
