import getError from '../getError';
import instance from '../instance';

export const getAgents = async query => {
  try {
    const res = await instance.get('/agents', { params: query });
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error consultando agentes.');
    throw newError;
  }
};

export const createAgent = async data => {
  try {
    const res = await instance.post('/agents', data);
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error creando agente.');
    throw newError;
  }
};

export const editAgent = async data => {
  try {
    const res = await instance.put('/agents', data);
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error editando agente.');
    throw newError;
  }
};
