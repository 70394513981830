import * as Yup from 'yup';

const agentNum = Yup.number()
  .typeError('Número invalido.')
  .positive('Requiere ser positivo.')
  .integer('Requiere ser entero.')
  .required('Requerido.');

const agentName = Yup.string()
  .max(128, 'Requiere máximo 128 caracteres.')
  .notOneOf(['n/a', 'N/A'], 'Nombre invalido.')
  .trim()
  .required('Requerido.');

const plazas = Yup.array()
  .of(Yup.object())
  .min(1, 'Requiere mínimo una plaza.')
  .required('Requerido.');

const isActive = Yup.bool().required();

export const AGENT_ADD_SCHEMA = Yup.object().shape({
  agentNum,
  agentName,
  plazas,
});

export const AGENT_EDIT_SCHEMA = Yup.object().shape({
  agentNum,
  agentName,
  plazas,
  isActive,
});
