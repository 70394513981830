import * as Yup from 'yup';

const date = Yup.date()
  .typeError('Fecha invalida.')
  .max(new Date(), 'Requiere la fecha actual o una pasada.')
  .required('Requerido.');

const amount = Yup.number()
  .typeError('Número invalido.')
  .positive('Requiere ser positivo.')
  .required('Requerido.');

const department = Yup.object().nullable();

const supplier = Yup.object().required('Requerido.').nullable();

const conceptGroup = Yup.object().required('Requerido.').nullable();

const concept = Yup.object().required('Requerido.').nullable();

const description = Yup.string()
  .min(4, 'Requiere mínimo 4 caracteres.')
  .max(256, 'Requiere máximo 256 caracteres.')
  .trim()
  .required('Requerido.');

export const ADD_NDE_SCHEMA = Yup.object().shape({
  date,
  amount,
  department,
  supplier,
  conceptGroup,
  concept,
  description,
});
