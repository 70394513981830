import getError from '../getError';
import instance from '../instance';

export const getDepartments = async query => {
  try {
    const res = await instance.get('/departments', { params: query });
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error consultando departamentos.');
    throw newError;
  }
};

export const createDepartment = async data => {
  try {
    const res = await instance.post('/departments', data);
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error creando departamento.');
    throw newError;
  }
};

export const editDepartment = async data => {
  try {
    const res = await instance.put('/departments', data);
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error editando departamento.');
    throw newError;
  }
};
