import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const ExpenseDetail = props => {
  const {
    content,
    contentColor = 'text.secondary',
    placement = 'bottom',
    title,
    titleColor = 'text.primary',
    tooltip = false,
  } = props;

  return (
    <Tooltip
      arrow
      placement={placement}
      sx={{
        cursor: tooltip ? 'pointer' : 'default',
      }}
      title={tooltip ? content : null}
    >
      <Box sx={{ display: 'inline-block' }}>
        <Box sx={{ gap: 1, display: 'flex' }}>
          {title && (
            <Typography color={titleColor} fontWeight="medium" variant="body2">
              {`${title}:`}
            </Typography>
          )}

          <Typography
            color={contentColor}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
            }}
            variant="body2"
          >
            {content}
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  );
};

ExpenseDetail.propTypes = {
  content: PropTypes.string.isRequired,
  contentColor: PropTypes.oneOf(['text.primary', 'text.secondary']),
  placement: PropTypes.oneOf(['top', 'bottom', 'right']),
  title: PropTypes.string,
  titleColor: PropTypes.oneOf(['text.primary', 'text.secondary']),
  tooltip: PropTypes.bool,
};

export default ExpenseDetail;
