import * as Yup from 'yup';

const supplierName = Yup.string()
  .max(128, 'Requiere máximo 128 caracteres.')
  .notOneOf(['n/a', 'N/A'], 'Nombre invalido.')
  .trim()
  .required('Requerido.');

const supplierRfc = Yup.string()
  .min(12, 'Requiere mínimo 12 caracteres.')
  .max(13, 'Requiere máximo 13 caracteres.')
  .trim()
  .when('hasRfc', (hasRfc, schema) => {
    if (hasRfc) {
      return schema.required('Requerido.');
    }
  });

const departments = Yup.array()
  .of(Yup.object())
  .min(1, 'Requiere mínimo una plaza.')
  .required('Requerido.');

const hasRfc = Yup.bool().required();

const isActive = Yup.bool().required();

export const SUPPLIER_ADD_SCHEMA = Yup.object().shape({
  supplierName,
  supplierRfc,
  departments,
  hasRfc,
});

export const SUPPLIER_EDIT_SCHEMA = Yup.object().shape({
  supplierName,
  supplierRfc,
  departments,
  hasRfc,
  isActive,
});
