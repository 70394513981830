import { useState, useEffect, useCallback } from 'react';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

import LoadingButton from '../../Components/LoadingButton';
import Modal from '../../Components/Modal';
import Table from '../../Components/Table';
import FormAutocomplete from '../../Components/forms/FormAutocomplete';
import FormSelect from '../../Components/forms/FormSelect';
import FormSwitch from '../../Components/forms/FormSwitch';
import FormTextbox from '../../Components/forms/FormTextbox';

import { ContentContainer, ContentBox, TableBox } from '../../theme';
import { useModal } from '../../hooks';
import {
  getUsers,
  getDepartments,
  editUser,
  createUser,
} from '../../api/services';
import { USER_ROLES } from '../../constants/select';
import { USER_COLUMNS } from '../../constants/tables/columns';
import { USER_INITIAL_VALUES } from '../../constants/forms/initialValues';
import {
  USER_EDIT_SCHEMA,
  USER_ADD_SCHEMA,
} from '../../constants/forms/schemas';
import { USER_FIELDS } from '../../constants/tables/fields';
import useAuth from '../../context/useAuth';

const Users = () => {
  const [changePassword, setChangePassword] = useState(false);
  const [editData, setEditData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [query] = useState({ isActive: true });
  const [users, setUsers] = useState(null);

  const { user, setUser } = useAuth();

  const [open, setOpen] = useModal({ ref: false });

  const fetchData = useCallback(async () => {
    try {
      const res = await getUsers({
        role:
          user.role === 'SUPERADMIN'
            ? ['USER', 'ANALYST', 'ADMIN']
            : ['USER', 'ANALYST'],
      });

      setUsers(res);
      setLoading(false);
    } catch (error) {
      if (error.status === 401) setUser(null);
      toast.error(error.message);
    }
  }, [user.role, setUser]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleEditRequest = data => {
    setEditData(data.row);
    setOpen(true);
  };

  const handleOpen = () => {
    setEditData(null);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setChangePassword(false);
    setError(null);
  };

  return (
    <>
      <ContentContainer maxWidth="xl">
        <ContentBox>
          <TableBox>
            <Box sx={{ alignSelf: 'flex-end' }}>
              <Button
                disabled={loading}
                endIcon={<AddIcon />}
                onClick={handleOpen}
                variant="contained"
              >
                Nuevo
              </Button>
            </Box>

            <Table
              columns={USER_COLUMNS(handleEditRequest)}
              loading={loading}
              rows={users}
            />
          </TableBox>
        </ContentBox>
      </ContentContainer>

      {/* New/edit Modal */}
      <Modal
        disabled={loading}
        onClose={handleClose}
        open={open}
        title={`${editData ? 'Editar' : 'Nuevo'} usuario`}
        width="md"
      >
        <Formik
          initialValues={
            editData ? { ...editData, password: '' } : USER_INITIAL_VALUES
          }
          onSubmit={async values => {
            try {
              setLoading(true);

              const data = {
                ...values,
                department: values.department?._id ?? null,
                password:
                  changePassword || !editData ? values.password : undefined,
              };

              editData ? await editUser(data) : await createUser(data);

              toast.success(
                `Usuario ${editData ? 'editado' : 'creado'} con éxito.`
              );

              await fetchData();
              handleClose();
            } catch (error) {
              setLoading(false);

              if (error.status === 409) {
                setError(error.message);
                return;
              }

              if (error.status === 401) setUser(null);
              toast.error(error.message);
            }
          }}
          validationSchema={
            editData ? USER_EDIT_SCHEMA(changePassword) : USER_ADD_SCHEMA
          }
        >
          {({ values }) => (
            <Form>
              <Grid container spacing={2} sx={{ p: 2, mb: 4 }}>
                {error && (
                  <Grid item xs={12}>
                    <Alert
                      onClose={() => setError(null)}
                      severity="error"
                      sx={{
                        mb: 2,
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: 'error.dark',
                      }}
                    >
                      {error}
                    </Alert>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <FormTextbox
                    disabled={loading}
                    label="Usuario"
                    name="username"
                  />
                </Grid>

                {((editData && changePassword) || !editData) && (
                  <Grid item xs={12}>
                    <FormTextbox
                      disabled={loading}
                      label="Contraseña"
                      name="password"
                      type="password"
                    />
                  </Grid>
                )}

                {editData && (
                  <Grid item xs={12}>
                    <Box sx={{ justifyContent: 'flex-end', display: 'flex' }}>
                      <Button
                        disabled={loading}
                        onClick={() => setChangePassword(!changePassword)}
                      >
                        {changePassword
                          ? 'Cancelar cambio'
                          : 'Cambiar contraseña'}
                      </Button>
                    </Box>
                  </Grid>
                )}

                {USER_FIELDS.map(({ name, label }) => (
                  <Grid item xs={12} md={6} key={name}>
                    <FormTextbox disabled={loading} label={label} name={name} />
                  </Grid>
                ))}

                <Grid item xs={12} md={6}>
                  <FormSelect
                    disabled={loading}
                    label="Rol"
                    name="role"
                    options={
                      user.role === 'SUPERADMIN'
                        ? USER_ROLES
                        : USER_ROLES.filter(role => role.value !== 'ADMIN')
                    }
                  />
                </Grid>

                {values.role === 'USER' && (
                  <Grid item xs={12} md={6}>
                    <FormAutocomplete
                      disabled={loading}
                      label="Departamento"
                      name="department"
                      property="departmentName"
                      query={query}
                      source={getDepartments}
                    />
                  </Grid>
                )}

                {editData && (
                  <Grid item xs={12}>
                    <FormSwitch
                      disabled={loading}
                      name="isActive"
                      offLabel="Inactivo"
                      onLabel="Activo"
                    />
                  </Grid>
                )}
              </Grid>

              {/* Buttons */}
              <Box
                sx={{
                  justifyContent: 'space-between',
                  display: 'flex',
                  width: '100%',
                  px: 2,
                }}
              >
                <Button color="error" disabled={loading} onClick={handleClose}>
                  Cancelar
                </Button>
                <LoadingButton
                  icon={editData ? <EditIcon /> : <AddIcon />}
                  loading={loading}
                  text={editData ? 'Editar' : 'Guardar'}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default Users;
