import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const NotFound = () => {
  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          height: '90vh',
        }}
      >
        <Box>
          <Box sx={{ alignItems: 'center', display: 'flex', mb: 2 }}>
            <Typography sx={{ fontSize: 114 }} variant="h1">
              4
            </Typography>
            <img alt="Jerry Berry" src="/images/jerryEmpty.svg" />
            <Typography sx={{ fontSize: 114 }} variant="h1">
              4
            </Typography>
          </Box>

          <Box sx={{ mb: 2 }}>
            <Typography variant="h3">Página no encontrada.</Typography>
            <Typography variant="h5">
              La página que buscas no existe o ha cambiado de dirección.
            </Typography>
          </Box>

          <Button component={Link} startIcon={<KeyboardBackspaceIcon />} to="/">
            regresar al inicio
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default NotFound;
