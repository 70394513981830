import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { formatCurrency } from '../../../../utils';

const Summary = props => {
  const { color = 'text.primary', children, loading, title } = props;

  return (
    <>
      <Box color="text.secondary">
        <Typography sx={{ fontSize: 'caption.fontSize' }} variant="subtitle2">
          {`${title.toUpperCase()}:`}
        </Typography>

        <Typography
          color={color}
          display="inline"
          sx={{
            fontSize: {
              xs: 'h5.fontSize',
              lg: 'h5.fontSize',
              xl: 'h4.fontSize',
            },
          }}
          variant="h4"
        >
          {loading ? (
            <Skeleton sx={{ width: 160 }} />
          ) : (
            formatCurrency(children)
          )}
        </Typography>

        {!loading && (
          <Typography
            display="inline"
            sx={{ fontSize: 'caption.fontSize' }}
            variant="subtitle1"
          >
            {' MXN'}
          </Typography>
        )}
      </Box>
    </>
  );
};

Summary.propTypes = {
  color: PropTypes.oneOf(['success.main', 'error.main']),
  children: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default Summary;
