import getError from '../getError';
import instance from '../instance';

export const getSuppliers = async query => {
  try {
    const res = await instance.get('/suppliers', { params: query });
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error consultando proveedores.');
    throw newError;
  }
};

export const createSupplier = async data => {
  try {
    const res = await instance.post('/suppliers', data);
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error creando proveedor.');
    throw newError;
  }
};

export const editSupplier = async data => {
  try {
    const res = await instance.put('/suppliers', data);
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error editando proveedor.');
    throw newError;
  }
};
