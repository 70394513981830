import {
  getZones,
  getPlazas,
  getAgents,
  getChains,
  getFamilies,
} from '../../../api/services';

const FIELDS = [
  {
    name: 'zone',
    property: 'zoneName',
    source: getZones,
    label: 'Zona',
    isDisabled: ({ plaza, agent, chain, family }) =>
      plaza || agent || chain || family ? true : false,
  },
  {
    name: 'plaza',
    property: 'plazaName',
    source: getPlazas,
    label: 'Plaza',
    isDisabled: ({ agent, chain, family }) => {
      if (agent || chain || family) return true;
      return false;
    },
  },
  {
    name: 'agent',
    property: 'agentName',
    source: getAgents,
    label: 'Agente',
    isDisabled: ({ zone, plaza, chain, family }) => {
      if (chain || family) return true;
      if (zone && !plaza) return true;
      return false;
    },
  },
  {
    name: 'chain',
    property: 'chainName',
    source: getChains,
    label: 'Cadena',
    isDisabled: ({ zone, plaza, agent, family }) => {
      if (family) return true;
      if ((zone || plaza) && !agent) return true;
      return false;
    },
  },
  {
    name: 'family',
    property: 'familyName',
    source: getFamilies,
    label: 'Familia',
    isDisabled: () => false,
  },
];

export default FIELDS;
