import { useState } from 'react';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import AddDeductible from './Components/AddDeductible';
import AddNonDeductible from './Components/AddNonDeductible';

import { ContentBox, ContentContainer } from '../../theme';

const AddExpenses = () => {
  const [value, setValue] = useState(0);

  const handleTabChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <ContentContainer maxWidth="xl">
        <ContentBox>
          <Tabs onChange={handleTabChange} value={value} variant="fullWidth">
            <Tab label="Deducibles" />
            <Tab label="No deducible" />
          </Tabs>

          {value === 0 && <AddDeductible />}
          {value === 1 && <AddNonDeductible />}
        </ContentBox>
      </ContentContainer>
    </>
  );
};
export default AddExpenses;
