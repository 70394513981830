import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const FormSelect = props => {
  const { disabled, label, name, options } = props;

  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const formConfig = {};

  if (meta.error && meta.touched) {
    formConfig.error = true;
    formConfig.helperText = meta.error;
  }

  return (
    <FormControl error={formConfig.error} fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        disabled={disabled}
        label={label}
        onChange={e => setFieldValue(name, e.target.value)}
        sx={{ bgcolor: '#FFF' }}
        value={field.value}
      >
        {options.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{formConfig.helperText}</FormHelperText>
    </FormControl>
  );
};

FormSelect.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default FormSelect;
