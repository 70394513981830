import getError from '../getError';
import instance from '../instance';

export const getConceptGroups = async query => {
  try {
    const res = await instance.get('/concept-groups', { params: query });
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error consultando grupos.');
    throw newError;
  }
};

export const createConceptGroup = async data => {
  try {
    const res = await instance.post('/concept-groups', data);
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error creando grupos.');
    throw newError;
  }
};

export const editConceptGroup = async data => {
  try {
    const res = await instance.put('/concept-groups', data);
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error editando grupos.');
    throw newError;
  }
};
