import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';

import Fab from '@mui/material/Fab';

const UploadButton = props => {
  const { error, isDrag, loading, onErrorClick, onFileSelected } = props;

  return (
    <Box
      sx={{
        position: 'relative',
        m: 1,
        pointerEvents: isDrag ? 'none' : 'auto',
      }}
    >
      <Fab color={error ? 'error' : 'primary'} onClick={error && onErrorClick}>
        {error && <KeyboardReturnIcon sx={{ color: 'common.white' }} />}
        {!error && (
          <Tooltip arrow title="Clic para subir archivo">
            <Box>
              <IconButton
                component="label"
                sx={{
                  width: 56,
                  height: 56,
                  backgroundColor: 'primary.main',
                  color: 'common.white',
                }}
              >
                <FileUploadOutlinedIcon sx={{ fontSize: 25 }} />
                <input
                  accept=".csv, .xlx, .xlsx"
                  hidden
                  onChange={onFileSelected}
                  type="file"
                />
              </IconButton>
            </Box>
          </Tooltip>
        )}
      </Fab>

      {loading && (
        <CircularProgress
          disableShrink
          size={68}
          sx={{
            position: 'absolute',
            zIndex: 1,
            top: -6,
            left: -6,
            color: 'success.main',
          }}
        />
      )}
    </Box>
  );
};

UploadButton.propTypes = {
  error: PropTypes.instanceOf(Error),
  isDrag: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onErrorClick: PropTypes.func.isRequired,
  onFileSelected: PropTypes.func.isRequired,
};

export default UploadButton;
