import getError from '../getError';
import instance from '../instance';

export const getDistributions = async query => {
  try {
    const res = await instance.get('/distributions', { params: query });
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error consultando distribuciones.');
    throw newError;
  }
};

export const createDistribution = async distribution => {
  try {
    const res = await instance.post('/distributions', distribution);
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error creando distribución.');
    throw newError;
  }
};

export const deleteDistribution = async id => {
  try {
    const res = await instance.delete('/distributions', { params: id });
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error borrando distribución.');
    throw newError;
  }
};
