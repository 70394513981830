import Proptypes from 'prop-types';
import { useState } from 'react';
import { useField } from 'formik';

import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const FormTextbox = props => {
  const { currency, percentage, disabled, label, name, multiline, type } =
    props;

  const [showPassword, setShowPassword] = useState(false);

  const [field, meta] = useField(name);

  const textFieldConfig = { ...field };

  if (meta.error && meta.touched) {
    textFieldConfig.error = true;
    textFieldConfig.helperText = meta.error;
  }

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = e => {
    e.preventDefault();
  };

  const handleStartAdorment = () => {
    if (currency) return '$';
    if (percentage) return '%';
  };

  return (
    <>
      <TextField
        {...textFieldConfig}
        autoComplete="off"
        disabled={disabled}
        fullWidth
        InputProps={{
          endAdornment: (
            <>
              {type === 'password' && (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              )}
            </>
          ),
          startAdornment: (currency || percentage) && (
            <InputAdornment position="start">
              {handleStartAdorment()}
            </InputAdornment>
          ),
        }}
        label={label}
        multiline={multiline}
        rows={3}
        sx={{ '& .MuiInputBase-root': { bgcolor: '#FFF' } }}
        type={showPassword ? 'string' : type}
        variant="outlined"
      />
    </>
  );
};

FormTextbox.prototypes = {
  currency: Proptypes.bool,
  percentage: Proptypes.bool,
  disabled: Proptypes.bool,
  label: Proptypes.string.isRequired,
  multiline: Proptypes.string,
  name: Proptypes.string.isRequired,
  type: Proptypes.oneOf(['string', 'password']),
};

export default FormTextbox;
