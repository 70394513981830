import { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

import LinkButton from './Components/LinkButton';
import getLinks from './utils/getLinks';
import useAuth from '../../context/useAuth';
import { logout } from '../../api/services/auth';

const drawerWidth = 240;

function ResponsiveDrawer({ children }) {
  const location = useLocation();

  const [mobileOpen, setMobileOpen] = useState(false);
  const [selected, setSelected] = useState(location.pathname);

  useEffect(() => {
    setSelected(location.pathname);
  }, [location]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const { user, setUser } = useAuth();

  const handleLogout = async () => {
    try {
      setUser(null);
      await logout();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const drawer = (
    <>
      <Toolbar />

      <Divider />

      {/* User role's dynamic links */}
      <List>
        {getLinks(user.role).map(([text, icon, path], i) => (
          <LinkButton
            key={i}
            text={text}
            icon={icon}
            path={path}
            selected={selected}
            setSelected={setSelected}
          />
        ))}

        <Divider />

        {/* Logout button */}
        <List>
          <ListItemButton onClick={handleLogout}>
            <Box sx={{ alignItems: 'center', gap: 1, display: 'flex' }}>
              <LogoutOutlinedIcon color="error" />
              <ListItemText
                primary={<Typography color="error">Salir</Typography>}
              />
            </Box>
          </ListItemButton>
        </List>
      </List>
    </>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      {/* Appbar */}
      <AppBar
        sx={{
          position: 'fixed',
          ml: { sm: `${drawerWidth}px` },
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar sx={{ display: 'flex' }}>
          {/* Mobile menu button */}
          <IconButton
            color="inherit"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: 'none' }, mr: 2 }}
          >
            <MenuIcon />
          </IconButton>

          {/* Logo */}
          <Box
            alt="Logo Bomuca"
            component="img"
            src="/images/Logo.png"
            sx={{ height: 25 }}
          />
        </Toolbar>
      </AppBar>

      {/* Drawer */}
      <Box
        component="nav"
        sx={{
          flexShrink: { sm: 0 },
          width: { sm: drawerWidth },
        }}
      >
        {/* Mobile Drawer */}
        <Drawer
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          onClose={handleDrawerToggle}
          open={mobileOpen}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          variant="temporary"
        >
          {drawer}
        </Drawer>

        {/* Desktop Drawer */}
        <Drawer
          open
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          variant="permanent"
        >
          {drawer}
        </Drawer>
      </Box>

      {/* Main content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Box sx={{ flexDirection: 'column', display: 'flex', height: '100vh' }}>
          <Toolbar />
          <Box sx={{ flexGrow: 1 }}>{children || <Outlet />}</Box>
        </Box>
      </Box>
    </Box>
  );
}

export default ResponsiveDrawer;
