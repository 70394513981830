const FIELDS = [
  {
    name: 'firstName',
    label: 'Nombre(s)',
  },
  {
    name: 'lastName',
    label: 'Apellidos',
  },
  {
    name: 'email',
    label: 'Email',
  },
];

export default FIELDS;
