const INITIAL_VALUES = {
  zone: null,
  plaza: null,
  agent: null,
  chain: null,
  family: null,
  amount: '',
};

export default INITIAL_VALUES;
