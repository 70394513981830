const getError = (error, message, customMessages) => {
  if (error.response?.status === 409)
    message = customMessages?.[409] || error.response?.data?.message;
  if (error.response?.status === 403)
    message = customMessages?.[403] || 'Solicitud prohibida.';
  if (error.response?.status === 401)
    message = customMessages?.[401] || 'La sesión ha expirado.';
  if (error.response?.status === 429)
    message =
      customMessages?.[429] ||
      'Excedió limite de solicitudes, intentar más tarde. ';

  const newError = new Error(message);
  newError.status = error.response?.status;
  return newError;
};

export default getError;
