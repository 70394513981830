import EditIcon from '@mui/icons-material/Edit';
import { GridActionsCellItem } from '@mui/x-data-grid';

const SUPPLIER_COLUMNS = handleEdit => [
  {
    field: 'supplierName',
    headerName: 'Nombre',
    flex: 6,
    minWidth: 300,
  },
  {
    field: 'supplierRfc',
    headerName: 'RFC',
    flex: 2,
    minWidth: 200,
    align: 'center',
    headerAlign: 'center',
    valueGetter: params => params.row.supplierRfc || 'N/A',
  },
  {
    field: 'departments',
    headerName: 'Departamentos',
    flex: 2,
    minWidth: 200,
    align: 'center',
    headerAlign: 'center',
    valueGetter: params =>
      params.row.departments
        .map(department => department.departmentName)
        .join(', '),
  },
  {
    field: 'isActive',
    headerName: 'Activo',
    type: 'boolean',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: 'Acciones',
    flex: 1,
    minWidth: 100,
    align: 'center',
    getActions: params => [
      <GridActionsCellItem
        icon={<EditIcon />}
        label="Editar"
        onClick={() => handleEdit(params)}
      />,
    ],
  },
];

export default SUPPLIER_COLUMNS;
