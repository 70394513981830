import * as Yup from 'yup';

const username = Yup.number()
  .typeError('Número invalido.')
  .positive('Requiere ser positivo.')
  .integer('Requiere ser entero.')
  .required('Requerido.');

const password = Yup.string()
  .min(8, 'Requiere mínimo 8 caracteres.')
  .max(128, 'Requiere máximo 128 caracteres.')
  .required('Requerido.');

export const LOGIN_SCHEMA = Yup.object().shape({
  username,
  password,
});
