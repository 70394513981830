import { Navigate } from 'react-router-dom';

import Spinner from './components/Spinner';

import useAuth from '../context/useAuth';

const PublicRoute = ({ children }) => {
  const { user, loading } = useAuth();

  if (loading) return <Spinner />;

  return user ? <Navigate to="/" replace /> : children;
};

export default PublicRoute;
