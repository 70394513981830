const INITIAL_VALUES = {
  username: '',
  password: '',
  firstName: '',
  lastName: '',
  email: '',
  role: '',
  department: null,
};

export default INITIAL_VALUES;
