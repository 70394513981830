import { useState, useEffect, useCallback } from 'react';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

import LoadingButton from '../../Components/LoadingButton';
import Modal from '../../Components/Modal';
import Table from '../../Components/Table';
import FormAutocomplete from '../../Components/forms/FormAutocomplete';
import FormSwitch from '../../Components/forms/FormSwitch';
import FormTextbox from '../../Components/forms/FormTextbox';
import { ContentBox, ContentContainer, TableBox } from '../../theme';
import {
  getChains,
  getFamilies,
  createFamily,
  editFamily,
} from '../../api/services';
import { useModal } from '../../hooks';
import { FAMILY_COLUMNS } from '../../constants/tables/columns';
import { FAMILY_INITIAL_VALUES } from '../../constants/forms/initialValues';
import {
  FAMILY_EDIT_SCHEMA,
  FAMILY_ADD_SCHEMA,
} from '../../constants/forms/schemas';
import useAuth from '../../context/useAuth';

const Families = () => {
  const [editData, setEditData] = useState(null);
  const [error, setError] = useState(null);
  const [families, setFamilies] = useState(null);
  const [loading, setLoading] = useState(true);
  const [query] = useState({ isActive: true });

  const { setUser } = useAuth();

  const [open, setOpen] = useModal({ ref: false });

  const fetchData = useCallback(async () => {
    try {
      const res = await getFamilies();
      setFamilies(res);
      setLoading(false);
    } catch (error) {
      if (error.status === 401) setUser(null);
      toast.error(error.message);
    }
  }, [setUser]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleEditRequest = data => {
    setEditData(data.row);
    setOpen(true);
  };

  const handleOpen = () => {
    setEditData(null);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setError(null);
  };

  return (
    <>
      <ContentContainer maxWidth="xl">
        <ContentBox>
          <TableBox>
            <Box sx={{ alignSelf: 'flex-end' }}>
              <Button
                disabled={loading}
                endIcon={<AddIcon />}
                onClick={handleOpen}
                variant="contained"
              >
                Nuevo
              </Button>
            </Box>

            <Table
              columns={FAMILY_COLUMNS(handleEditRequest)}
              loading={loading}
              rows={families}
            />
          </TableBox>
        </ContentBox>
      </ContentContainer>

      {/* New/edit Modal */}
      <Modal
        disabled={loading}
        onClose={handleClose}
        open={open}
        title={`${editData ? 'Editar' : 'Nueva'} familia`}
        width="sm"
      >
        <Formik
          initialValues={editData || FAMILY_INITIAL_VALUES}
          onSubmit={async values => {
            try {
              setLoading(true);

              const chains = values.chains.map(chain => chain._id);
              const data = { ...values, chains };

              editData ? await editFamily(data) : await createFamily(data);
              toast.success(
                `Familia ${editData ? 'editada' : 'creada'} con éxito.`
              );

              await fetchData();
              handleClose();
            } catch (error) {
              setLoading(false);

              if (error.status === 409) {
                setError(error.message);
                return;
              }

              if (error.status === 401) setUser(null);
              toast.error(error.message);
            }
          }}
          validationSchema={editData ? FAMILY_EDIT_SCHEMA : FAMILY_ADD_SCHEMA}
        >
          {props => (
            <Form>
              <Grid container spacing={2} sx={{ p: 2, mb: 4 }}>
                {error && (
                  <Grid item xs={12}>
                    <Alert
                      onClose={() => setError(null)}
                      severity="error"
                      sx={{
                        mb: 2,
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: 'error.dark',
                      }}
                    >
                      {error}
                    </Alert>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <FormTextbox
                    disabled={loading}
                    label="Número"
                    name="familyNum"
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormTextbox
                    disabled={loading}
                    label="Nombre"
                    name="familyName"
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormAutocomplete
                    disabled={loading}
                    query={query}
                    label="Cadenas"
                    multiple
                    name="chains"
                    property="chainName"
                    source={getChains}
                  />
                </Grid>

                {editData && (
                  <Grid item xs={12}>
                    <FormSwitch
                      disabled={loading}
                      name="isActive"
                      offLabel="Inactivo"
                      onLabel="Activo"
                    />
                  </Grid>
                )}
              </Grid>

              {/* Buttons */}
              <Box
                sx={{
                  justifyContent: 'space-between',
                  display: 'flex',
                  width: '100%',
                  px: 2,
                }}
              >
                <Button color="error" disabled={loading} onClick={handleClose}>
                  Cancelar
                </Button>
                <LoadingButton
                  icon={editData ? <EditIcon /> : <AddIcon />}
                  loading={loading}
                  text={editData ? 'Editar' : 'Guardar'}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default Families;
