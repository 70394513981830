import EditIcon from '@mui/icons-material/Edit';
import { GridActionsCellItem } from '@mui/x-data-grid';

const CONCEPT_COLUMNS = handleEdit => [
  {
    field: 'conceptName',
    headerName: 'Concepto',
    flex: 4,
    minWidth: 300,
  },
  {
    field: 'conceptGroup',
    headerName: 'Grupo',
    flex: 3,
    minWidth: 200,
    align: 'center',
    headerAlign: 'center',
    valueGetter: params => params.row.conceptGroup.conceptGroupName,
  },
  {
    field: 'isActive',
    headerName: 'Activo',
    type: 'boolean',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: 'Acciones',
    flex: 1,
    minWidth: 100,
    align: 'center',
    getActions: params => [
      <GridActionsCellItem
        icon={<EditIcon />}
        label="Editar"
        onClick={() => handleEdit(params)}
      />,
    ],
  },
];

export default CONCEPT_COLUMNS;
