import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const FormSwitch = props => {
  const { disabled, name, onLabel, offLabel } = props;

  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={field.value}
            disabled={disabled}
            onChange={(_, newValue) => setFieldValue(name, newValue)}
          />
        }
        label={field.value ? onLabel : offLabel}
      />
    </>
  );
};

FormSwitch.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onLabel: PropTypes.string.isRequired,
  offLabel: PropTypes.string.isRequired,
};

export default FormSwitch;
