import getError from '../getError';
import instance from '../instance';

export const getParticipations = async query => {
  try {
    const res = await instance.get('/participations', { params: query });
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error consultando participaciones.');
    throw newError;
  }
};

export const createParticipation = async data => {
  try {
    const res = await instance.post('/participations', data);
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error creando participación.');
    throw newError;
  }
};

export const editParticipation = async data => {
  try {
    const res = await instance.put('/participations', data);
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error editando participación.');
    throw newError;
  }
};
