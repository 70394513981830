import getError from '../getError';
import instance from '../instance';

export const getChains = async query => {
  try {
    const res = await instance.get('/chains', { params: query });
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error consultando cadenas.');
    throw newError;
  }
};

export const createChain = async data => {
  try {
    const res = await instance.post('/chains', data);
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error creando cadena.');
    throw newError;
  }
};

export const editChain = async data => {
  try {
    const res = await instance.put('/chains', data);
    return res.data;
  } catch (error) {
    const newError = getError(error, 'Error editando cadena.');
    throw newError;
  }
};
