import * as Yup from 'yup';

const year = Yup.number()
  .typeError('Número invalido.')
  .positive('Requiere ser positivo.')
  .min(
    new Date().getFullYear() - 3,
    `Requiere ser mayor que ${new Date().getFullYear() - 4}`
  )
  .required('Requerido.');

const chain = Yup.object().required('Requerido.').nullable();

const percentage = Yup.number()
  .typeError('Número invalido.')
  .positive('Requiere ser positivo.')
  .required('Requerido.');

const isActive = Yup.bool().required();

export const PARTICIPATION_ADD_SCHEMA = Yup.object().shape({
  year,
  chain,
  percentage,
});

export const PARTICIPATION_EDIT_SCHEMA = Yup.object().shape({
  year,
  chain,
  percentage,
  isActive,
});
