import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import Distributions from './Components/Distributions';
import ExpenseCard from './Components/ExpenseCard';
import ExpenseCount from './Components/ExpenseCount';
import DateRangePicker from '../../Components/dates/DateRangePicker';
import { getExpenses, deleteExpense } from '../../api/services';
import useAuth from '../../context/useAuth';
import { ContentContainer } from '../../theme';

const Expenses = () => {
  const [dates, setDates] = useState([]);
  const [dateState, setDateState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [expenses, setExpenses] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [incomplete, setIncomplete] = useState(0);
  const [complete, setComplete] = useState(0);
  const [idQuery, setIdQuery] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState(null);

  const { user, setUser } = useAuth();

  const hasValidQuery = dates.length > 1 || idQuery !== null;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const res = await getExpenses({
          _id: idQuery,
          department: user.department?._id,
          date: dates,
        });

        setExpenses(res.expenses);
        setIncomplete(res.incomplete);
        setComplete(res.complete);

        setLoading(false);
      } catch (error) {
        if (error.status === 401) setUser(null);
        toast.error(error.message);
      }
    };

    if (hasValidQuery && !selectedExpense) fetchData();
  }, [
    hasValidQuery,
    idQuery,
    dates,
    user.department,
    selectedExpense,
    setUser,
  ]);

  const handleDelete = async expense => {
    try {
      setLoading(true);

      await deleteExpense({ _id: expense._id });

      handleClean();

      toast.success('El gasto fue borrado con éxito.');
    } catch (error) {
      if (error.status === 401) setUser(null);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleClean = () => {
    setDates([]);
    setIdQuery(null);
    setExpenses(null);
    setStatusFilter(null);
  };

  const getSearchText = () => {
    if (user.role === 'USER') return 'Seleccionar las fechas de consulta.';
    if (user.role === 'ADMIN') return 'Seleccionar ID de gasto.';
    if (user.role === 'SUPERADMIN')
      return 'Seleccionar las fechas de consulta o ID de gasto.';
  };

  const handleStatusChange = status => {
    if (statusFilter?.status === status) {
      setStatusFilter(null);
      return;
    }
    if (status === 'incomplete') setStatusFilter({ status, isComplete: false });
    if (status === 'complete') setStatusFilter({ status, isComplete: true });
  };

  if (selectedExpense) {
    return (
      <Distributions
        exit={() => {
          setExpenses(null);
          setSelectedExpense(null);
        }}
        selectedExpense={selectedExpense}
      />
    );
  }

  return (
    <>
      <ContentContainer maxWidth="xl">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            py: 4,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            {!idQuery &&
              (user.role === 'USER' || user.role === 'SUPERADMIN') && (
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <DateRangePicker
                    alignment="left"
                    dates={dates}
                    dateState={dateState}
                    handleDatePick={val => {
                      setExpenses(null);
                      setDates(val);
                    }}
                    setDateState={setDateState}
                  />

                  {dates.length > 0 && (
                    <Button onClick={handleClean}>Limpiar</Button>
                  )}
                </Box>
              )}

            {dates.length <= 0 &&
              (user.role === 'ADMIN' || user.role === 'SUPERADMIN') && (
                <TextField
                  label="ID"
                  onChange={e => {
                    setExpenses(null);
                    if (e.target.value.length === 24)
                      setIdQuery(e.target.value);
                    if (e.target.value.length !== 24) setIdQuery(null);
                  }}
                  sx={{ bgcolor: '#FFF', width: 250 }}
                  value={idQuery || ''}
                />
              )}
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexGrow: 1,
              gap: 1,
            }}
          >
            {loading && <CircularProgress color="primary" />}

            {!loading && expenses && expenses.length <= 0 && hasValidQuery && (
              <>
                <ErrorOutlineIcon color="error" />
                <Typography variant="h5" color="error">
                  No se encontraron resultados.
                </Typography>
              </>
            )}

            {!loading && !expenses && dates.length <= 1 && (
              <>
                <SearchIcon />
                <Typography variant="h5" color="primary.main">
                  {getSearchText()}
                </Typography>
              </>
            )}

            {!loading && expenses && expenses.length > 0 && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  width: '100%',
                  gap: 6,
                }}
              >
                <Box
                  sx={{
                    display: 'flex ',
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: { xs: 0, md: 4 },
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <ExpenseCount
                    onClick={() => handleStatusChange('incomplete')}
                    selected={statusFilter?.status === 'incomplete'}
                    title="Incompleto"
                    icon={CancelIcon}
                    color="error.main"
                  >
                    {incomplete}
                  </ExpenseCount>

                  <ExpenseCount
                    onClick={() => handleStatusChange('complete')}
                    selected={statusFilter?.status === 'complete'}
                    title="Completo"
                    icon={CheckCircleIcon}
                    color="success.main"
                  >
                    {complete}
                  </ExpenseCount>
                </Box>

                <Grid container spacing={6}>
                  {expenses.length <= 0 ? (
                    <>
                      <Grid item xs={12} md={6} lg={4} xl={3}>
                        <Skeleton height={345} variant="rectangular" />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4} xl={3}>
                        <Skeleton height={345} variant="rectangular" />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4} xl={3}>
                        <Skeleton height={345} variant="rectangular" />
                      </Grid>
                    </>
                  ) : (
                    expenses
                      .filter(expense =>
                        statusFilter
                          ? expense.isComplete === statusFilter.isComplete
                          : true
                      )
                      .map(expense => (
                        <Grid
                          item
                          key={expense._id}
                          xs={12}
                          md={6}
                          lg={4}
                          xl={3}
                        >
                          <ExpenseCard
                            expense={expense}
                            id={idQuery}
                            onSelect={expense => setSelectedExpense(expense)}
                            onDelete={handleDelete}
                          />
                        </Grid>
                      ))
                  )}
                </Grid>
              </Box>
            )}
          </Box>
        </Box>
      </ContentContainer>
    </>
  );
};

export default Expenses;
