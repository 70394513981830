import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const Modal = props => {
  const { children, disabled, onClose, open, title, width } = props;

  return (
    <Dialog
      fullWidth
      maxWidth={width}
      onClose={disabled ? null : onClose}
      open={open}
    >
      <DialogTitle
        sx={{
          justifyContent: 'center',
          display: 'flex',
          bgcolor: 'common.white',
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent sx={{ bgcolor: 'common.white' }}>{children}</DialogContent>
    </Dialog>
  );
};

Modal.propTypes = {
  children: PropTypes.element.isRequired,
  disabled: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
};

export default Modal;
