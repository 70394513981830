import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

const LinkButton = ({ text, icon: Icon, path, selected, setSelected }) => {
  return (
    <ListItemButton
      component={Link}
      key={path}
      onClick={() => setSelected(path)}
      selected={path === selected}
      to={path}
    >
      <Box sx={{ alignItems: 'center', gap: 2, display: 'flex' }}>
        <Icon />
        <ListItemText primary={text} />
      </Box>
    </ListItemButton>
  );
};

LinkButton.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  selected: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
};

export default LinkButton;
