import { Navigate } from 'react-router-dom';

import useAuth from '../context/useAuth';

const RoleRouter = ({ roles, children }) => {
  const { user } = useAuth();

  if (roles.includes(user.role) || user.role === 'SUPERADMIN') return children;

  return <Navigate to="/" replace />;
};

export default RoleRouter;
