import EditIcon from '@mui/icons-material/Edit';
import { GridActionsCellItem } from '@mui/x-data-grid';

import { USER_ROLES } from '../../../constants/select';

const USER_COLUMNS = handleEdit => [
  {
    field: 'username',
    headerName: 'Usuario',
    maxWidth: 100,
  },
  {
    field: 'firstName',
    headerName: 'Nombre(s)',
    flex: 4,
    minWidth: 200,
  },
  {
    field: 'lastName',
    headerName: 'Apellidos',
    flex: 4,
    minWidth: 200,
  },
  {
    field: 'role',
    headerName: 'Rol',
    flex: 2,
    minWidth: 200,
    align: 'center',
    headerAlign: 'center',
    valueGetter: params =>
      USER_ROLES.find(role => role.value === params.row.role).label,
  },
  {
    field: 'department',
    headerName: 'Departamento',
    flex: 2,
    minWidth: 200,
    align: 'center',
    headerAlign: 'center',
    valueGetter: params => params.row.department?.departmentName || 'N/A',
  },
  {
    field: 'isActive',
    headerName: 'Activo',
    type: 'boolean',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: 'Acciones',
    flex: 1,
    minWidth: 100,
    align: 'center',
    getActions: params => [
      <GridActionsCellItem
        icon={<EditIcon />}
        label="Editar"
        onClick={() => handleEdit(params)}
      />,
    ],
  },
];

export default USER_COLUMNS;
