import * as Yup from 'yup';

const plazaNum = Yup.number()
  .typeError('Número invalido.')
  .positive('Requiere ser positivo.')
  .integer('Requiere ser entero.')
  .required('Requerido.');

const plazaName = Yup.string()
  .max(128, 'Requiere máximo 128 caracteres.')
  .notOneOf(['n/a', 'N/A'], 'Nombre invalido.')
  .trim()
  .required('Requerido.');

const zone = Yup.object().required('Requerido.').nullable();

const isActive = Yup.bool().required();

export const PLAZA_ADD_SCHEMA = Yup.object().shape({
  plazaNum,
  plazaName,
  zone,
});

export const PLAZA_EDIT_SCHEMA = Yup.object().shape({
  plazaNum,
  plazaName,
  zone,
  isActive,
});
