import { useState } from 'react';
import { toast } from 'react-toastify';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

import LoadingButton from '../../../../Components/LoadingButton';
import Table from '../../../../Components/Table';
import UploadArea from '../../../../Components/UploadArea';

import { TableBox } from '../../../../theme';
import { excelDateToDate } from '../../../../utils';
import {
  getSuppliers,
  createDeductibleExpenses,
} from '../../../../api/services';
import {
  ADD_DE_COLUMNS,
  ADD_DE_EXCEL_OPTIONAL_HEADERS,
  ADD_DE_EXCEL_REQUIRED_HEADERS,
  ADD_DE_EXCEL_STATUS_VALID,
} from '../../../../constants/tables/columns';
import useAuth from '../../../../context/useAuth';

const AddDeductible = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const { user, setUser } = useAuth();

  const fetchSuppliers = async () => {
    try {
      return await getSuppliers({
        departments: user.department?._id,
        isActive: true,
      });
    } catch (error) {
      if (error.status === 401) setUser(null);
      throw error;
    }
  };

  const handleUpload = async json => {
    try {
      // Validate that required headers and data are present in excel.
      const validData = json.filter(row => {
        return Object.values(ADD_DE_EXCEL_REQUIRED_HEADERS).every(val =>
          row.hasOwnProperty(val)
        );
      });

      if (validData.length <= 0)
        throw new Error(
          'No se encontraron gastos válidos. (Revisar el archivo y títulos de las columnas.'
        );

      // Fetch suppliers
      const suppliers = await fetchSuppliers();

      // Filter inactive department
      const activeDepartmentSuppliers = suppliers.filter(supplier =>
        supplier.departments.every(department => department.isActive)
      );

      if (activeDepartmentSuppliers.length <= 0) {
        throw new Error(`El departamento se encuentra inactivo.`);
      }

      // Filters department expenses and valid expenses
      const validDepartmentExpenses = validData
        .filter(row =>
          activeDepartmentSuppliers.some(
            supplier =>
              row[ADD_DE_EXCEL_REQUIRED_HEADERS.supplierRfc] ===
              supplier.supplierRfc
          )
        )
        .filter(
          row =>
            row[ADD_DE_EXCEL_REQUIRED_HEADERS.status] ===
            ADD_DE_EXCEL_STATUS_VALID
        );

      if (validDepartmentExpenses.length <= 0)
        throw new Error(
          'No se encontraron gastos que apliquen al departamento.'
        );

      // Adds saved supplier name to table and supplier id to later send to server
      // sorts by date (ASC)
      const finalExpenses = validDepartmentExpenses
        .map(expense => {
          const supplier = activeDepartmentSuppliers.find(
            supplier =>
              supplier.supplierRfc ===
              expense[ADD_DE_EXCEL_REQUIRED_HEADERS.supplierRfc]
          );

          expense.supplier = supplier._id;
          expense.supplierName = supplier.supplierName;
          return expense;
        })
        .sort(
          (a, b) =>
            a[ADD_DE_EXCEL_REQUIRED_HEADERS.date] -
            b[ADD_DE_EXCEL_REQUIRED_HEADERS.date]
        );

      setData(finalExpenses);
    } catch (error) {
      setError(error);
    }
  };

  const handleDelete = async row => {
    const newData = data.filter(
      val => val[ADD_DE_EXCEL_REQUIRED_HEADERS.uuid] !== row.id
    );
    setData(newData);
  };

  const handleSave = async () => {
    try {
      if (!user.department) {
        toast.error('Usuario no cuenta con departamento');
        return;
      }

      setLoading(true);

      const newExpenses = data.map(expense => {
        return {
          department: user.department?._id,
          supplier: expense.supplier,
          conceptGroup: null,
          concept: null,
          uuid: expense[ADD_DE_EXCEL_REQUIRED_HEADERS.uuid],
          invoice: expense[ADD_DE_EXCEL_OPTIONAL_HEADERS.invoice],
          date: excelDateToDate(expense[ADD_DE_EXCEL_REQUIRED_HEADERS.date]),
          description: expense[ADD_DE_EXCEL_REQUIRED_HEADERS.description],
          amount: Number(expense[ADD_DE_EXCEL_REQUIRED_HEADERS.amount]).toFixed(
            2
          ),
          isDeductible: true,
        };
      });

      await createDeductibleExpenses(newExpenses);

      toast.success(`Gastos agregados con éxito.`);

      handleClose();
    } catch (error) {
      setLoading(false);

      if (error.status === 401) setUser(null);
      toast.error(error.message);
    }
  };

  const handleClose = () => {
    setData([]);
    setLoading(false);
  };

  return (
    <>
      {data.length <= 0 ? (
        <UploadArea error={error} onLoad={handleUpload} setError={setError} />
      ) : (
        <>
          <TableBox>
            <Table
              columns={ADD_DE_COLUMNS(handleDelete)}
              getId={ADD_DE_EXCEL_REQUIRED_HEADERS.uuid}
              rows={data}
              loading={loading}
            />

            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <Button color="error" disabled={loading} onClick={handleClose}>
                Cancelar
              </Button>
              <LoadingButton
                icon={<AddIcon />}
                loading={loading}
                onClick={handleSave}
                text="Guardar"
              />
            </Box>
          </TableBox>
        </>
      )}
    </>
  );
};

export default AddDeductible;
